import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    IconButton,
    Autocomplete,
    CircularProgress,
    TextField,
    Container,
    Box,
    Avatar,
    List,
    ListItem,
    ListItemText,
    Checkbox,
    Step,
    StepLabel,
    Stepper,
    ListItemAvatar,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Card,
    CardContent,
    Grid,
    Chip,
    Divider,
    CardHeader,
    StepConnector,
    styled,
} from '@mui/material';
import { Typography as AntTypography } from 'antd';
import palomita from '../../../../public/assets/palomita.svg';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import femaleStudent from '../../../assets/img/female_student.png';
import MaleStudent from '../../../assets/img/male_student.png';
import defaultStudent from '../../../assets/img/default_student.png';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth, useFeedback } from '../../../../hooks';
import { useTheme } from '@emotion/react';

import { fetchPartialities } from '../../../store/partialities/fetch';

import {
    selectConceptsByStudent,
    selectStudentsWithPendingConcepts,
} from '../../../store/income/selectors';
import { useFormik } from 'formik';
import { CreatePaymnet } from '../../../store/income/operationSlice';
import { GavelOutlined, LocalAtm } from '@mui/icons-material';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import useThemeColors from '../../../../theme/themes';
import {
    fetchExport,
    NewfetchCreatePayment,
} from '../../../store/income/fetchSlice';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DiscountIcon from '@mui/icons-material/Discount';
import { differenceInDays, parseISO } from 'date-fns';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const NewIncomeModal = ({ open, onClose }) => {
    /////////////////////LOCAL STATE/////////////////////
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [partialities, setPartialities] = useState([]);

    const [paymentMethod, setPaymentMethod] = useState(null);
    const [selectedConcept, setSelectedConcept] = useState(null);

    const [loading, setLoading] = useState(true);
    const [loadingPartialities, setLoadingPartialities] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [stepType, setStepType] = useState(null);
    const [selectedPartialities, setSelectedPartialities] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
        '& .MuiStepConnector-line': {
            minHeight: '50px',
        },
        '& .MuiStepConnector-icon': {
            marginLeft: '-12px',
        },
    }));
    const CompletedStepIcon = styled(CheckCircleIcon)(({ theme }) => ({
        color: '#000000',
    }));
    const ActiveStepIcon = styled(RadioButtonCheckedIcon)(({ theme }) => ({
        color: 'black',
    }));

    const NonActiveStepIcon = styled(CircleOutlinedIcon)(({ theme }) => ({
        color: 'grey',
    }));
    /////////////////////SHARED STATE/////////////////////
    const auth = useAuth();
    const schoolId = auth.getUser().school_id;
    const dispatch = useDispatch();
    const theme = useTheme();
    const feedbackApp = useFeedback();
    const colors = useThemeColors();

    /////////////////////USE EFFECT/////////////////////
    useEffect(() => {
        if (open) {
            dispatch(NewfetchCreatePayment(schoolId)).finally(() =>
                setLoading(false)
            );
        }
    }, [dispatch, schoolId, open]);
    useEffect(() => {
        if (selectedConcept && selectedConcept.concept_id) {
            setLoadingPartialities(true);
            dispatch(fetchPartialities(selectedConcept.concept_id))
                .unwrap()
                .then((payload) => {
                    setPartialities(payload.partialities);
                })
                .catch((error) => {
                    console.error('Fetch partialities error:', error);
                })
                .finally(() => setLoadingPartialities(false));
        }
    }, [dispatch, selectedConcept]);

    /////////////////////SELECTORS/////////////////////
    const studentsWithPendingConcepts = useSelector(
        selectStudentsWithPendingConcepts
    );
    const concepts = useSelector((state) =>
        selectConceptsByStudent(state, selectedStudent?.student_id)
    );

    /////////////////////FORMIK////////////////////
    const formik = useFormik({
        initialValues: {
            user_id: null,
            concept_id: null,
            payment_method: null,
            partialities: [],
        },
        onSubmit: async (values) => {
            const data = {
                payment_method: values.payment_method,
                partialities: selectedPartialities,
            };

            if (selectedPartialities.length === 0) {
                delete data.number_terms;
            }

            await dispatch(
                CreatePaymnet({
                    school_id: schoolId,
                    concept_id: values.concept_id,
                    user_id: values.user_id,
                    data: data,
                })
            )
                .unwrap()
                .then((result) => {
                    feedbackApp.showFeedback({
                        title: 'Pago registrado con éxito',
                    });
                    const paymentsIds = result.payment.map(
                        (item) => item.payment_id
                    );
                    dispatch(
                        fetchExport({
                            schoolId: schoolId,
                            data: paymentsIds,
                        })
                    );
                    formik.resetForm();
                    setSelectedPartialities([]);
                    setSelectAll(false);
                    setPartialities([]);
                    setActiveStep(0);
                    onClose();
                })
                .catch((error) => {
                    feedbackApp.showFeedback({
                        title: error.feedback.title,
                    });
                });
        },
    });

    /////////////////////HANDLERS/////////////////////

    const handleNext = (type = null) => {
        setStepType(type);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 3 && selectedPartialities.length === 0) {
            setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 2, 0));
        } else {
            setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
        }
    };

    const updateSelectionState = (newSelectedPartialities) => {
        const visiblePartialities = partialities.filter(
            (partiality) => !partiality.paid
        );
        setSelectAll(
            newSelectedPartialities.length === visiblePartialities.length
        );
        formik.setFieldValue('number_terms', newSelectedPartialities.length);
    };

    const handleSelectPartiality = (partialityId, index) => {
        setSelectedPartialities((prevSelected) => {
            let newSelected;
            if (prevSelected.includes(partialityId)) {
                newSelected = prevSelected.filter((id) => {
                    const partialityIndex = partialities.findIndex(
                        (p) => p.partiality_id === id
                    );
                    return partialityIndex < index;
                });
            } else {
                newSelected = [...prevSelected, partialityId];
            }
            updateSelectionState(newSelected);
            return newSelected;
        });
    };
    const handleReset = () => {
        setSelectedPartialities([]);
        setSelectAll(false);
        setPartialities([]);
        setActiveStep(0);
        onClose();
    };
    const handleSelectAll = () => {
        const visiblePartialities = partialities.filter(
            (partiality) => !partiality.paid && !partiality.expired
        );
        const newSelectedPartialities = selectAll
            ? []
            : visiblePartialities.map((partiality) => partiality.partiality_id);
        setSelectedPartialities(newSelectedPartialities);
        updateSelectionState(newSelectedPartialities);
        setSelectAll(!selectAll);
    };

    const totalAmount = selectedPartialities.reduce((total, partialityId) => {
        const partiality = partialities.find(
            (p) => p.partiality_id === partialityId
        );
        return total + (partiality ? partiality.cantidad : 0);
    }, 0);

    const calculateDaysRemaining = (paymentDate) => {
        const today = new Date();
        const paymentDay = parseISO(paymentDate);
        return differenceInDays(paymentDay, today);
    };

    const getColorBasedOnDaysRemaining = (daysRemaining) => {
        if (daysRemaining > 8) {
            return 'green';
        } else if (daysRemaining >= 4 && daysRemaining <= 8) {
            return 'orange';
        } else {
            return 'red';
        }
    };

    /////////////////////////PAYMENT METHODS/////////////////////////
    const paymentMethods = [
        {
            value: 1,
            label: 'Efectivo',
            bgColor: colors.velvetNight[100],
            iconColor: colors.velvetNight[500],
            icon: <PaymentIcon />,
        },
        {
            value: 2,
            label: 'Transferencia',
            bgColor: colors.sunsetBlush[100],
            iconColor: colors.sunsetBlush[500],
            icon: <AccountBalanceIcon />,
        },
        {
            value: 3,
            label: 'Tarjeta de crédito o débito',
            bgColor: colors.green[200],
            iconColor: colors.green[600],
            icon: <CreditCardIcon />,
        },
        {
            value: 4,
            label: 'Tarjeta prepago',
            bgColor: colors.twilight[100],
            iconColor: colors.twilight[500],
            icon: <CardGiftcardIcon />,
        },
        {
            value: 5,
            label: 'Domiciliación bancaria',
            bgColor: colors.coral[100],
            iconColor: colors.coral[500],
            icon: <AccountBalanceWalletIcon />,
        },
    ];

    /////////////////////////STEPS/////////////////////////
    const steps = [
        {
            label: 'Seleccionar Estudiante y Método de pago',

            content: (
                <Container>
                    <Autocomplete
                        options={studentsWithPendingConcepts}
                        noOptionsText="No hay opciones disponibles"
                        getOptionLabel={(option) =>
                            `${option.name} ${option.last_name} ${option.second_last_name}`
                        }
                        value={
                            studentsWithPendingConcepts.find(
                                (student) =>
                                    student.student_id === formik.values.user_id
                            ) || null
                        }
                        onChange={(event, newValue) => {
                            setSelectedStudent(newValue);
                            formik.setFieldValue(
                                'user_id',
                                newValue?.student_id
                            );
                        }}
                        renderOption={(props, option) => {
                            const avatarSrc =
                                option.url_photo_profile ||
                                (option.gender === 'M'
                                    ? femaleStudent
                                    : option.gender === 'H'
                                    ? MaleStudent
                                    : defaultStudent);

                            return (
                                <Box
                                    component="li"
                                    {...props}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mb: 1,
                                        p: 1,
                                        borderRadius: 1,
                                        '&:hover': {
                                            backgroundColor:
                                                theme.palette.action.hover,
                                        },
                                    }}>
                                    <Avatar
                                        sx={{ width: 56, height: 56, mr: 2 }}
                                        src={avatarSrc}
                                        alt={option.name}
                                    />
                                    <Box>
                                        <Typography variant="body1">
                                            {' '}
                                            {option.name} {option.last_name}{' '}
                                            {option.second_last_name}{' '}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary">
                                            ID: {option.student_id}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary">
                                            Teléfono: {option.cellphone}
                                        </Typography>
                                    </Box>
                                </Box>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Seleccionar estudiante"
                                variant="outlined"
                                helperText={
                                    formik.touched.student &&
                                    formik.errors.student
                                }
                                error={
                                    formik.touched.student &&
                                    Boolean(formik.errors.student)
                                }
                            />
                        )}
                        sx={{
                            borderRadius: 10,
                            width: '100%',
                            height: '100%',
                            mt: 2,
                            mb: 2,
                        }}
                    />

                    <Autocomplete
                        noOptionsText="No hay opciones disponibles"
                        options={paymentMethods}
                        getOptionLabel={(option) => option.label}
                        value={
                            paymentMethods.find(
                                (method) =>
                                    method.value ===
                                    formik.values.payment_method
                            ) || null
                        }
                        onChange={(event, newValue) => {
                            if (newValue) {
                                setPaymentMethod(newValue);
                                formik.setFieldValue(
                                    'payment_method',
                                    newValue.value
                                );
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Seleccionar método de pago"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                        renderOption={(props, option) => (
                            <Box
                                component="li"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    p: 1,
                                }}
                                {...props}>
                                <Box
                                    sx={{
                                        backgroundColor: option.bgColor,
                                        color: option.iconColor,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: 32,
                                        height: 32,
                                    }}>
                                    {option.icon}
                                </Box>
                                <Typography sx={{ ml: 2 }}>
                                    {option.label}
                                </Typography>
                            </Box>
                        )}
                        sx={{
                            borderRadius: 10,
                            width: '100%',
                            height: '100%',
                            mt: 2,
                            mb: 2,
                        }}
                    />
                </Container>
            ),
        },
        {
            label: 'Seleccionar Concepto del estudiante',
            content: (
                <Container>
                    <Box sx={{ flexWrap: 'wrap', gap: 2 }}>
                        {concepts.map((concept) => (
                            <Grid item key={concept.concept_id} xs={12} sm={6}>
                                <Card
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                        boxShadow: 3,
                                        borderRadius: 2,
                                    }}>
                                    <CardContent
                                        sx={{
                                            flexGrow: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                            padding: 3,
                                        }}>
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                fontWeight: 'bold',
                                                mb: 1,
                                            }}>
                                            {concept.catalogConcept?.concept}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            color="textSecondary">
                                            {concept.description}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                            }}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                }}>
                                                <Box
                                                    sx={{
                                                        borderRadius: '100%',
                                                        height: 25,
                                                        width: 25,
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        backgroundColor:
                                                            colors.red[200],
                                                    }}>
                                                    <AttachMoneyIcon
                                                        fontSize="small"
                                                        sx={{
                                                            color: colors
                                                                .red[600],
                                                        }}
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        justifyContent:
                                                            'space-between',
                                                    }}>
                                                    <Typography
                                                        sx={{
                                                            ml: 1,
                                                        }}
                                                        variant="body2">
                                                        Recargo:
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            color: colors
                                                                .red[500],
                                                        }}>
                                                        + $
                                                        {
                                                            concept.surcharge_total
                                                        }
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    paddingTop: 1,
                                                }}>
                                                <Box
                                                    sx={{
                                                        borderRadius: '100%',
                                                        height: 25,
                                                        width: 25,
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        backgroundColor:
                                                            colors.green[200],
                                                    }}>
                                                    <DiscountIcon
                                                        fontSize="small"
                                                        sx={{
                                                            color: colors
                                                                .green[600],
                                                        }}
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        justifyContent:
                                                            'space-between',
                                                    }}>
                                                    <Typography
                                                        sx={{ ml: 1 }}
                                                        variant="body2">
                                                        Descuento:
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            color: colors
                                                                .green[500],
                                                        }}>
                                                        - $
                                                        {concept.discount_total}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Divider
                                                sx={{
                                                    width: '100%',
                                                }}
                                                flexItem>
                                                <Chip
                                                    label="Totales"
                                                    size="small"
                                                />
                                            </Divider>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    paddingTop: 1,
                                                }}>
                                                <Box
                                                    sx={{
                                                        borderRadius: '100%',
                                                        height: 25,
                                                        width: 25,
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        backgroundColor:
                                                            colors.orange[200],
                                                    }}>
                                                    <LocalAtm
                                                        fontSize="small"
                                                        sx={{
                                                            color: colors
                                                                .orange[600],
                                                        }}
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        justifyContent:
                                                            'space-between',
                                                    }}>
                                                    <Typography
                                                        sx={{
                                                            ml: 1,
                                                        }}
                                                        variant="body2">
                                                        Subtotal:
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        ${concept.subtotal}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    paddingTop: 1,
                                                }}>
                                                <Box
                                                    sx={{
                                                        borderRadius: '100%',
                                                        height: 25,
                                                        width: 25,
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        backgroundColor:
                                                            colors.teal[200],
                                                    }}>
                                                    <LocalAtm
                                                        fontSize="small"
                                                        sx={{
                                                            color: colors
                                                                .teal[600],
                                                        }}
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        justifyContent:
                                                            'space-between',
                                                    }}>
                                                    <Typography
                                                        sx={{
                                                            ml: 1,
                                                        }}
                                                        variant="body2">
                                                        Total:
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        ${concept.net_total}
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    paddingTop: 1,
                                                }}>
                                                <Box
                                                    sx={{
                                                        borderRadius: '100%',
                                                        height: 25,
                                                        width: 25,
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        backgroundColor:
                                                            colors
                                                                .sunsetBlush[200],
                                                    }}>
                                                    <LocalAtm
                                                        fontSize="small"
                                                        sx={{
                                                            color: colors
                                                                .sunsetBlush[600],
                                                        }}
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        justifyContent:
                                                            'space-between',
                                                    }}>
                                                    <Typography
                                                        sx={{
                                                            ml: 1,
                                                        }}
                                                        variant="body2">
                                                        Restante:
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        ${concept.remaining}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                    {concept.in_agreement === true ? (
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            endIcon={
                                                <NavigateNextOutlinedIcon />
                                            }
                                            onClick={() => {
                                                setSelectedConcept(concept);
                                                formik.setFieldValue(
                                                    'concept_id',
                                                    concept.concept_id
                                                );
                                                setActiveStep(2);
                                            }}
                                            sx={{
                                                borderTopLeftRadius: 0,
                                                borderTopRightRadius: 0,
                                                mt: 'auto',
                                            }}>
                                            Ver parcialidades
                                        </Button>
                                    ) : (
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            endIcon={
                                                <NavigateNextOutlinedIcon />
                                            }
                                            onClick={() => {
                                                setSelectedConcept(concept);
                                                formik.setFieldValue(
                                                    'concept_id',
                                                    concept.concept_id
                                                );
                                                setActiveStep(3);
                                            }}
                                            sx={{
                                                borderTopLeftRadius: 0,
                                                borderTopRightRadius: 0,
                                                mt: 'auto',
                                            }}>
                                            Pagar Concepto
                                        </Button>
                                    )}
                                </Card>
                            </Grid>
                        ))}
                    </Box>
                </Container>
            ),
        },
        {
            label: 'Seleccionar Parcialidades',
            content: (
                <Container sx={{ width: '100%', height: '100%' }}>
                    <Box sx={{ mt: 3 }}>
                        <Accordion expanded={true}>
                            <AccordionSummary
                                expandIcon={false}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexGrow: 1,
                                    }}>
                                    <GavelOutlined />
                                    <Typography sx={{ ml: 1 }}>
                                        {' '}
                                        {
                                            selectedConcept?.catalogConcept
                                                .concept
                                        }{' '}
                                    </Typography>
                                </Box>
                                <Chip
                                    Filled
                                    color="primary"
                                    sx={{ borderRadius: 10, color: 'white' }}
                                    label={`${partialities?.length}`}
                                />
                            </AccordionSummary>
                            <AccordionDetails>
                                {loadingPartialities ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%',
                                        }}>
                                        {' '}
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <>
                                        <Box sx={{ mb: 2 }}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                <Checkbox
                                                    sx={{
                                                        color: 'red',
                                                        '&.Mui-checked': {
                                                            color: 'red',
                                                        },
                                                    }}
                                                    checked={selectAll}
                                                    onChange={handleSelectAll}
                                                />
                                                <Typography>
                                                    {' '}
                                                    Pagar todas las
                                                    parcialidades
                                                </Typography>
                                            </Box>
                                        </Box>

                                        {partialities
                                            .filter(
                                                (partiality) => !partiality.paid
                                            )
                                            .map(
                                                (
                                                    partiality,
                                                    index,
                                                    filteredPartialities
                                                ) => {
                                                    const isDisabled =
                                                        partiality.expired ||
                                                        (index !== 0 &&
                                                            !selectAll &&
                                                            !selectedPartialities.includes(
                                                                filteredPartialities[
                                                                    index - 1
                                                                ].partiality_id
                                                            ));
                                                    return (
                                                        <List
                                                            key={
                                                                partiality.partiality_id
                                                            }>
                                                            <ListItem>
                                                                <Checkbox
                                                                    checked={selectedPartialities.includes(
                                                                        partiality.partiality_id
                                                                    )}
                                                                    onChange={() =>
                                                                        handleSelectPartiality(
                                                                            partiality.partiality_id,
                                                                            index
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        isDisabled
                                                                    }
                                                                />
                                                                <ListItemText
                                                                    primary={
                                                                        <Box
                                                                            display="flex"
                                                                            alignItems="center">
                                                                            {'parcialidad ' +
                                                                                partiality.partialitie_number}
                                                                            {partiality.expired && (
                                                                                <Chip
                                                                                    label="Expirada"
                                                                                    color="secondary"
                                                                                    sx={{
                                                                                        ml: 2,
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </Box>
                                                                    }
                                                                    secondary={
                                                                        index ===
                                                                        0 ? (
                                                                            <Box
                                                                                display="flex"
                                                                                alignItems="center">
                                                                                <CalendarTodayOutlinedIcon
                                                                                    sx={{
                                                                                        color: getColorBasedOnDaysRemaining(
                                                                                            calculateDaysRemaining(
                                                                                                partiality.payment_date
                                                                                            )
                                                                                        ),
                                                                                        transition:
                                                                                            'color 0.5s ease',
                                                                                        marginRight: 1,
                                                                                        animation:
                                                                                            calculateDaysRemaining(
                                                                                                partiality.payment_date
                                                                                            ) <=
                                                                                            3
                                                                                                ? 'pulse 1.5s infinite'
                                                                                                : 'none',
                                                                                    }}
                                                                                />
                                                                                <Typography
                                                                                    sx={{
                                                                                        color: getColorBasedOnDaysRemaining(
                                                                                            calculateDaysRemaining(
                                                                                                partiality.payment_date
                                                                                            )
                                                                                        ),
                                                                                        fontWeight:
                                                                                            'bold',
                                                                                        transition:
                                                                                            'color 0.5s ease',
                                                                                        animation:
                                                                                            calculateDaysRemaining(
                                                                                                partiality.payment_date
                                                                                            ) <=
                                                                                            3
                                                                                                ? 'pulse 1.5s infinite'
                                                                                                : 'none',
                                                                                    }}>
                                                                                    {`${calculateDaysRemaining(
                                                                                        partiality.payment_date
                                                                                    )} días restantes`}
                                                                                </Typography>
                                                                            </Box>
                                                                        ) : null
                                                                    }
                                                                />
                                                                <ListItemAvatar>
                                                                    <Avatar
                                                                        sx={{
                                                                            width: 60,
                                                                            backgroundColor:
                                                                                colors
                                                                                    .teal[100],
                                                                            color: colors
                                                                                .teal[400],
                                                                            fontWeight:
                                                                                'bold',
                                                                        }}>
                                                                        ${' '}
                                                                        {
                                                                            partiality.cantidad
                                                                        }
                                                                    </Avatar>
                                                                </ListItemAvatar>
                                                            </ListItem>
                                                        </List>
                                                    );
                                                }
                                            )}

                                        <Divider sx={{ mt: 2, mb: 2 }} />

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}>
                                            <Typography variant="h6">
                                                {' '}
                                                Total a pagar:
                                            </Typography>
                                            <Chip
                                                label={`$${totalAmount}`}
                                                color="primary"
                                                sx={{
                                                    color: 'white',
                                                    fontSize: '1rem',
                                                }}
                                            />
                                        </Box>
                                    </>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Container>
            ),
        },
        {
            label: 'Confirmar pago',
            content: (
                <Container>
                    <Card
                        sx={{
                            p: 0,
                            boxShadow: 3,
                        }}>
                        <CardHeader
                            title="Resumen del Pago"
                            sx={{
                                textAlign: 'center',
                                backgroundColor: '#f5f5f5',
                                borderRadius: '4px',
                            }}
                        />
                        <CardContent>
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                    <Avatar
                                        alt={selectedStudent?.name}
                                        src={
                                            selectedStudent?.photoUrl ||
                                            (selectedStudent?.gender === 'M'
                                                ? MaleStudent
                                                : femaleStudent)
                                        }
                                        sx={{
                                            width: 56,
                                            height: 56,
                                            mr: 2,
                                        }}
                                    />
                                    <Typography variant="h6" component="div">
                                        {selectedStudent?.name}{' '}
                                        {selectedStudent?.last_name}{' '}
                                        {selectedStudent?.second_last_name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={{ my: 2 }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography
                                            variant="h6"
                                            component="div"
                                            sx={{
                                                pb: 1,
                                                mb: 2,
                                                fontWeight: 'bold',
                                            }}>
                                            Concepto:
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            component="li">
                                            {
                                                selectedConcept?.catalogConcept
                                                    ?.concept
                                            }{' '}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography
                                            variant="h6"
                                            component="div"
                                            sx={{
                                                pb: 1,
                                                mb: 2,
                                                fontWeight: 'bold',
                                            }}>
                                            Descripción:
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            component="li">
                                            {' '}
                                            {selectedConcept?.description}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={{ my: 2 }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                        <Box
                                            sx={{
                                                borderRadius: '100%',
                                                height: 25,
                                                width: 25,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor:
                                                    colors.red[200],
                                                mr: 1,
                                            }}>
                                            <DiscountIcon
                                                fontSize="small"
                                                sx={{ color: colors.red[600] }}
                                            />
                                        </Box>
                                        <Typography
                                            variant="h6"
                                            component="div"
                                            sx={{ flexGrow: 1 }}>
                                            {' '}
                                            Recargo:
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            color="textSecondary">
                                            <Box
                                                component="span"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    color: colors.red[600],
                                                }}>
                                                +{' '}
                                            </Box>
                                            ${selectedConcept?.surcharge_total}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                        <Box
                                            sx={{
                                                borderRadius: '100%',
                                                height: 25,
                                                width: 25,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor:
                                                    colors.green[200],
                                                mr: 1,
                                            }}>
                                            <DiscountIcon
                                                fontSize="small"
                                                sx={{
                                                    color: colors.green[600],
                                                }}
                                            />
                                        </Box>
                                        <Typography
                                            variant="h6"
                                            component="div"
                                            sx={{ flexGrow: 1 }}>
                                            {' '}
                                            Descuento:{' '}
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            color="textSecondary"
                                            sx={{ alignItems: 'center' }}>
                                            <Box
                                                component="span"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    color: colors.green[600],
                                                }}>
                                                -{' '}
                                            </Box>
                                            ${selectedConcept?.discount_total}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={{ my: 2 }} />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                    <Box
                                        sx={{
                                            borderRadius: '100%',
                                            height: 25,
                                            width: 25,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: colors.teal[200],
                                        }}>
                                        <LocalAtm
                                            fontSize="small"
                                            sx={{ color: colors.teal[600] }}
                                        />
                                    </Box>
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        sx={{ flexGrow: 1 }}>
                                        {' '}
                                        Total:{' '}
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        color="textSecondary"
                                        sx={{ fontWeight: 'bold' }}>
                                        {' '}
                                        $ {selectedConcept?.net_total}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Container>
            ),
        },
    ];

    /////////////////////RENDERS////////////////////

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose();
                }
            }}
            disableEscapeKeyDown
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: 5,
                    width: '90%',
                    maxWidth: 900,
                    height: '90%',
                    maxHeight: 700,
                },
            }}>
            <DialogTitle
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: 1,
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Box
                        component="img"
                        src={palomita}
                        alt="Descripción de la imagen"
                        sx={{
                            width: '15%',
                            height: 'auto',
                        }}
                    />
                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{ display: 'flex', ml: 2, mr: 2 }}
                    />
                    <AntTypography.Title level={5}>
                        Registrar pago
                    </AntTypography.Title>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{ display: 'flex', ml: 2, mr: 2 }}
                    />
                    <IconButton
                        onClick={() => {
                            formik.resetForm();
                            setActiveStep(0);
                            onClose();
                        }}>
                        <CloseIcon sx={{ color: '#000000ff', width: 20 }} />
                    </IconButton>
                </Box>
            </DialogTitle>
            <Divider sx={{ width: '95%' }} flexItem variant="middle" />
            <DialogContent sx={{ padding: '10px' }}>
                <Box sx={{ display: 'flex', mt: 2 }}>
                    <Stepper
                        activeStep={activeStep}
                        orientation="vertical"
                        connector={<CustomStepConnector />}
                        sx={{
                            height: '50px',
                            '& .MuiStep-root': {
                                '& .MuiStepLabel-root': {
                                    padding: 0,
                                },
                            },
                            '& .MuiStepConnector-root': {
                                marginLeft: '11px',
                            },
                        }}>
                        {steps.map((step, index) => {
                            const stepProps = {
                                completed:
                                    activeStep > index ||
                                    steps[index].completed,
                            };
                            const labelProps = {
                                StepIconComponent:
                                    activeStep === index
                                        ? ActiveStepIcon
                                        : steps[index].completed ||
                                          activeStep > index
                                        ? CompletedStepIcon
                                        : NonActiveStepIcon,
                            };
                            if (index === 0) {
                                labelProps.optional = (
                                    <Typography variant="caption">
                                        {step.description}
                                    </Typography>
                                );
                            }
                            return (
                                <Step key={step.label} {...stepProps}>
                                    <StepLabel {...labelProps}>
                                        {step.label}
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ mx: 2, height: '527px' }}
                    />
                    <Box sx={{ flexGrow: 1 }}>{steps[activeStep].content}</Box>
                </Box>
            </DialogContent>
            <Divider
                sx={{
                    width: '95%',
                }}
                flexItem
                variant="middle"
            />
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Box sx={{ mb: 2 }}>
                    <Button
                        sx={{ mt: 1, mr: 1 }}
                        onClick={() => {
                            handleReset();
                        }}>
                        Cancelar
                    </Button>
                </Box>

                {activeStep === 0 && (
                    <Box display="flex" alignItems="center" gap={1}>
                        <Button disabled> Atrás </Button>
                        <Button
                            variant="contained"
                            disabled={!selectedStudent || !paymentMethod}
                            onClick={handleNext}>
                            Siguiente
                        </Button>
                    </Box>
                )}

                {activeStep === 1 && (
                    <Box display="flex" alignItems="center" gap={1}>
                        <Button onClick={handleBack}> Atrás </Button>
                        <Button
                            variant="contained"
                            disabled
                            onClick={handleNext}>
                            Siguiente
                        </Button>
                    </Box>
                )}

                {activeStep === 2 && (
                    <Box display="flex" alignItems="center" gap={1}>
                        <Button onClick={handleBack}> Atrás </Button>
                        {stepType === 'Parcialidades' ? (
                            <Button
                                variant="contained"
                                fullWidth
                                color="primary"
                                onClick={formik.handleSubmit}
                                disabled={
                                    formik.isSubmitting ||
                                    !formik.values.number_terms
                                }>
                                {formik.isSubmitting ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    'Pagar'
                                )}
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                fullWidth
                                color="primary"
                                onClick={formik.handleSubmit}
                                disabled={
                                    formik.isSubmitting ||
                                    !formik.isValid ||
                                    !formik.dirty
                                }>
                                {formik.isSubmitting ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    'Pagar'
                                )}
                            </Button>
                        )}
                    </Box>
                )}
                {activeStep === 3 && (
                    <Box display="flex" alignItems="center" gap={1}>
                        <Button onClick={handleBack}> Atrás </Button>
                        {stepType === 'Parcialidades' ? (
                            <Button
                                variant="contained"
                                fullWidth
                                color="primary"
                                onClick={formik.handleSubmit}
                                disabled={
                                    formik.isSubmitting ||
                                    !formik.values.number_terms
                                }>
                                {formik.isSubmitting ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    'Pagar'
                                )}
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                fullWidth
                                color="primary"
                                onClick={formik.handleSubmit}
                                disabled={
                                    formik.isSubmitting ||
                                    !formik.isValid ||
                                    !formik.dirty
                                }>
                                {formik.isSubmitting ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    'Pagar'
                                )}
                            </Button>
                        )}
                    </Box>
                )}
            </DialogActions>
        </Dialog>
    );
};
export default NewIncomeModal;
