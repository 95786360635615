import React from 'react';
import { Grid, TextField, Box, Typography, Divider } from '@mui/material';

const AddressForm = ({ formik }) => {
    return (
        <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 2 }}>
            <form onSubmit={formik.handleSubmit}>
                <Divider>
                    <Typography variant="h5" align="center" gutterBottom>
                        Datos de Trabajo
                    </Typography>
                </Divider>
                <Typography
                    variant="body1"
                    align="center"
                    gutterBottom
                    sx={{ mb: 2, color: 'gray' }}
                >
                    Este formulario es para recopilar información opcional sobre
                    la ubicación de su lugar de trabajo. Si lo desea, puede
                    completar los campos relacionados con la dirección para
                    facilitar un posible contacto físico en caso de ser
                    necesario.
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Calle"
                            name="work_street"
                            value={formik.values.work_street}
                            onChange={formik.handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.work_street &&
                                Boolean(formik.errors.work_street)
                            }
                            helperText={
                                formik.touched.work_street &&
                                formik.errors.work_street
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Número Exterior"
                            name="work_external_number"
                            value={formik.values.work_external_number}
                            onChange={formik.handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.work_external_number &&
                                Boolean(formik.errors.work_external_number)
                            }
                            helperText={
                                formik.touched.work_external_number &&
                                formik.errors.work_external_number
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Número Interior"
                            name="work_internal_number"
                            value={formik.values.work_internal_number}
                            onChange={formik.handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={
                                formik.touched.work_internal_number &&
                                Boolean(formik.errors.work_internal_number)
                            }
                            helperText={
                                formik.touched.work_internal_number &&
                                formik.errors.work_internal_number
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Colonia"
                            name="work_neighborhood"
                            value={formik.values.work_neighborhood}
                            onChange={formik.handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.work_neighborhood &&
                                Boolean(formik.errors.work_neighborhood)
                            }
                            helperText={
                                formik.touched.work_neighborhood &&
                                formik.errors.work_neighborhood
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Código Postal"
                            name="work_zipcode"
                            value={formik.values.work_zipcode}
                            onChange={formik.handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                maxLength: 5,
                                onKeyDown: (e) => {
                                    if (
                                        !/^\d$/.test(e.key) &&
                                        e.key !== 'Backspace'
                                    ) {
                                        e.preventDefault();
                                    }
                                },
                            }}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.work_zipcode &&
                                Boolean(formik.errors.work_zipcode)
                            }
                            helperText={
                                formik.touched.work_zipcode &&
                                formik.errors.work_zipcode
                            }
                        />
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default AddressForm;
