import React, { useMemo, useState } from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    InputAdornment,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SchoolIcon from '../../../../assets/iconos/school-svg-com.svg';
import { faSchoolFlag } from '@fortawesome/free-solid-svg-icons';
import { throttle, debounce } from 'lodash';
import { getTurnLevel } from '../../../../../libs/utils';
import Connection from '../../../../../service/Connection';
import { Map, Marker } from 'pigeon-maps';

export const StudentPostulation = ({
    data,
    setData,
    handleBack,
    handleNext,
}) => {
    /////// LOCAL STATE //////
    const [schoolCards, setSchoolCards] = useState(data.postulations);
    const isNextDisabled = schoolCards.some(
        (card) => !card.school || Object.keys(card.school).length === 0
    );
    console.log(data, 'aja');
    const selectedSchools = schoolCards
        .filter((card) => card.school && card.school.school_id)
        .map((card) => card.school.school_id);

    const fetchThrottledAndDebounced = useMemo(
        () =>
            throttle(
                debounce((search, index) => {
                    if (search.length >= 3) {
                        Connection.findPostulationSchools({
                            name: search,
                            level: data.preinscriptionLevel,
                        })
                            .then((res) => {
                                const updatedCards = [...schoolCards];
                                updatedCards[index].options =
                                    res.data.data || [];
                                updatedCards[index].loading = false;
                                setSchoolCards(updatedCards);
                            })
                            .catch((err) => {
                                console.error(err);
                            });
                    } else {
                        const updatedCards = [...schoolCards];
                        updatedCards[index].options = [];
                        updatedCards[index].loading = false;
                        setSchoolCards(updatedCards);
                    }
                }, 1000),
                1000
            ),
        [schoolCards, data.preinscriptionLevel]
    );

    const handleInputChange = (index, newValue) => {
        const updatedCards = [...schoolCards];
        updatedCards[index].inputValue = newValue;
        updatedCards[index].loading = true;
        setSchoolCards(updatedCards);

        fetchThrottledAndDebounced(newValue, index);
    };

    const handleSelectionChange = (index, newValue) => {
        const updatedCards = [...schoolCards];
        updatedCards[index].school = newValue || {};
        setSchoolCards(updatedCards);

        const updatedData = updatedCards.map((card) => ({
            ...card,
        }));

        setData({
            ...data,
            postulations: updatedData,
        });
    };

    const handleSiblingsChange = (index, value) => {
        const updatedCards = [...schoolCards];
        updatedCards[index].siblings_number = value;
        setSchoolCards(updatedCards);

        const updatedData = updatedCards.map((card) => ({
            priority: card.priority,
            school: card.school,
            inputValue: card.inputValue,
            options: card.options,
            siblings_number: card.siblings_number,
        }));

        setData({
            ...data,
            postulations: updatedData,
        });
    };
    return (
        <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 2 }}>
            <Typography variant="h4" sx={{ textAlign: 'center' }}>
                Postulaciones
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {schoolCards.map((card, index) => {
                        return (
                            <Card
                                sx={{
                                    mt: 1,
                                    boxShadow:
                                        '0px 4px 12px rgba(0, 0, 0, 0.15)',
                                    backgroundColor: '#ffffff',
                                    border: '1px solid #ddd',
                                    borderRadius: '8px',
                                    marginBottom: '16px',
                                    '&:hover': {
                                        boxShadow:
                                            '0px 6px 16px rgba(0, 0, 0, 0.25)',
                                    },
                                }}
                                key={index}
                            >
                                <CardHeader
                                    title={`${card.label}`}
                                    //subheader="Por favor ingresa en el campo de texto el nombre de la escuela"
                                />
                                <CardContent>
                                    <Autocomplete
                                        disablePortal
                                        options={card.options.filter(
                                            (option) =>
                                                !selectedSchools.includes(
                                                    option.school_id
                                                )
                                        )}
                                        loading={card.loading}
                                        loadingText={
                                            card.inputValue.length < 3
                                                ? 'Escribe para iniciar búsqueda'
                                                : 'Buscando...'
                                        }
                                        noOptionsText={
                                            card.inputValue.length < 3
                                                ? 'Escribe para iniciar búsqueda'
                                                : []
                                                ? 'No se encontraron resultados'
                                                : ''
                                        }
                                        groupBy={(option) => option.state}
                                        getOptionLabel={(option) =>
                                            `${
                                                option.name
                                            }, Turno:  ${getTurnLevel(
                                                option.turn
                                            )} CCT: ${option.cct} - ${
                                                option.street
                                            } ${option.colony} #${
                                                option.no_ext
                                            }`
                                        }
                                        inputValue={card.inputValue}
                                        onInputChange={(event, newInputValue) =>
                                            handleInputChange(
                                                index,
                                                newInputValue
                                            )
                                        }
                                        onChange={(event, newValue) =>
                                            handleSelectionChange(
                                                index,
                                                newValue
                                            )
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                label="Escuela"
                                                type="text"
                                                variant="outlined"
                                                placeholder="Escribe el nombre de la escuela"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: (
                                                        <>
                                                            <InputAdornment position="start">
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faSchoolFlag
                                                                    }
                                                                    size="lg"
                                                                />
                                                            </InputAdornment>
                                                            {
                                                                params
                                                                    .InputProps
                                                                    .startAdornment
                                                            }
                                                        </>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                    {card.school && card.school.name && (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                mt: 2,
                                            }}
                                        >
                                            {/* Información de la escuela */}
                                            <Box
                                                sx={{
                                                    flex: 1,
                                                    maxWidth: '60%',
                                                }}
                                            >
                                                <Typography variant="h6">
                                                    {card.school.name}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {`Turno: ${getTurnLevel(
                                                        card.school.turn
                                                    )}`}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {`Dirección: ${card.school.street} #${card.school.no_ext}  ${card.school.colony}`}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {`Código Postal:  ${card.school.zip_code}`}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {`Estado:  ${card.school.state}`}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {`Teléfono:  ${card.school.phone}`}
                                                </Typography>
                                                <Box sx={{ mt: 2 }}>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{ mb: 1 }}
                                                    >
                                                        Número de hermanos en la
                                                        escuela
                                                    </Typography>
                                                    <Select
                                                        value={
                                                            card.siblings_number
                                                        }
                                                        size="small"
                                                        onChange={(e) =>
                                                            handleSiblingsChange(
                                                                index,
                                                                e.target.value
                                                            )
                                                        }
                                                        variant="outlined"
                                                    >
                                                        {[...Array(11)].map(
                                                            (_, i) => (
                                                                <MenuItem
                                                                    key={i}
                                                                    value={i}
                                                                >
                                                                    {i}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </Box>
                                            </Box>
                                            <Box
                                                sx={{
                                                    flex: 1,
                                                    maxWidth: '70%',
                                                    height: '200px',
                                                    backgroundColor: '#f0f0f0',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    border: '1px dashed #ccc',
                                                }}
                                            >
                                                {card.school.latitude &&
                                                card.school.longitude ? (
                                                    <Map
                                                        height={200}
                                                        defaultCenter={[
                                                            card.school
                                                                .latitude,
                                                            card.school
                                                                .longitude,
                                                        ]}
                                                        mouseEvents={true}
                                                        touchEvents={true}
                                                        zoomSnap={true}
                                                        zoomDelta={0}
                                                        defaultZoom={15}
                                                    >
                                                        <Marker
                                                            width={50}
                                                            anchor={[
                                                                card.school
                                                                    .latitude,
                                                                card.school
                                                                    .longitude,
                                                            ]}
                                                        >
                                                            <img
                                                                src={SchoolIcon}
                                                                width={50}
                                                                height={50}
                                                                alt="marker"
                                                            />
                                                        </Marker>
                                                    </Map>
                                                ) : (
                                                    <Typography textAlign="center">
                                                        Ubicación no disponible
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>
                                    )}
                                </CardContent>
                            </Card>
                        );
                    })}
                </Grid>
            </Grid>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 2,
                }}
            >
                <Button className="" type="default" onClick={handleBack}>
                    Regresar
                </Button>
                <Button
                    type="primary"
                    variant="contained"
                    onClick={handleNext}
                    disabled={isNextDisabled}
                >
                    Siguiente
                </Button>
            </Box>
        </Box>
    );
};
