import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth, useFeedback } from '../../../hooks';
import { LoadingButton } from '@mui/lab';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Avatar,
    Box,
    Skeleton,
    Stack,
} from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import * as Style from '../../../styles/index';
import {
    getParentsStudents,
    invalidateParentsStudents,
    selectParentsStudents,
    selectSelectedParentStatus,
} from '../../../store/slices/usersUI';
import { Error } from '../../../components/Feedback';
import { getLevelName, getTurnLevel } from '../../../libs/utils';
import MaleIcon from '@mui/icons-material/Man';
import FemaleIcon from '@mui/icons-material/Woman';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import NoDataOverlay from '../../../components/utilities/NoDataOverlay';
import { GridToolBar } from '../../../components/utilities/GridToolBar';
import CustomPagination from '../../../components/utilities/CustomPagination';

const RelationshipsModal = ({ open, setOpen, viewModel }) => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    const schoolId = Auth.getUser().school_id;
    const classes = Style.tableModalStyle();

    const students = useSelector(selectParentsStudents(viewModel.uuid));

    const statusOperation = useSelector(selectSelectedParentStatus);

    const config = useSelector(selectSettingBySchoolId(schoolId));
    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    const parentId = viewModel.ui.user_id;

    useEffect(() => {
        if (open) {
            dispatch(getParentsStudents({ parentId, schoolId }));
        }
    }, [open]);

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
        }
        setOpen(false);
    };

    const reloadStundents = () => {
        dispatch(getParentsStudents({ parentId, schoolId }))
            .unwrap()
            .then(() => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const invalidateUI = () => {
        dispatch(invalidateParentsStudents(viewModel.uuid));
        reloadStundents();
    };

    const getRelationshipIcon = (relationship) => {
        if (relationship === 'Padre') {
            return <MaleIcon sx={{ color: '#99ccff' }} />;
        }
        if (relationship === 'Madre') {
            return <FemaleIcon sx={{ color: '#ff99cc' }} />;
        }
        return <SupervisorAccountIcon sx={{ color: '#cccccc' }} />;
    };

    const columns = [
        {
            field: 'student_id',
            headerName: 'ID',
            flex: 0.1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'url_photo_profile',
            headerName: 'Foto',
            flex: 0.1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Avatar src={params.value} alt={params.row.name} />
            ),
        },
        {
            field: 'name',
            headerName: 'Nombre Completo',
            flex: 0.4,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <Typography>{`${row.name} ${row.last_name} ${row.second_last_name}`}</Typography>
            ),
        },
        {
            field: 'cellphone',
            headerName: 'Teléfono',
            flex: 0.2,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'group',
            headerName: 'Grupo',
            flex: 0.4,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ row }) => {
                return row.group;
            },
            renderCell: ({ row }) => {
                if (row.group) {
                    return (
                        <Typography>{`${row.group.grade} ${
                            row.group.group
                        } ${getTurnLevel(row.group.turn)} ${getLevelName(
                            row.group.level
                        )}`}</Typography>
                    );
                } else {
                    return <Typography>Sin Grupo</Typography>;
                }
            },
        },
        {
            field: 'parents',
            headerName: 'Padres/Tutores',
            flex: 0.5,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({ row }) => (
                <Stack spacing={1} sx={{ width: '100%' }}>
                    {row.parents && row.parents.length > 0 ? (
                        row.parents.map((parent, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    whiteSpace: 'normal', // Permite que el texto se ajuste.
                                    wordBreak: 'break-word',
                                }}
                            >
                                {getRelationshipIcon(parent.relationship)}
                                <Typography variant="body2">
                                    <strong>{parent.relationship}:</strong>{' '}
                                    {`${parent.name} ${
                                        parent.last_name || ''
                                    } ${parent.second_last_name || ''}`}
                                </Typography>
                            </Box>
                        ))
                    ) : (
                        <Typography>Sin Padres/Tutores</Typography>
                    )}
                </Stack>
            ),
        },
        ,
    ];

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth="lg"
            classes={{ paper: classes.dialogWrapper }}
            onClose={handleClose}
        >
            <DialogTitle
                style={{
                    backgroundColor: titleBarBackground.value,
                    color: fontColor.value,
                }}
            >
                <div style={{ display: 'flex' }}>
                    <Typography
                        variant="h6"
                        component="div"
                        style={{ flexGrow: 1 }}
                    >
                        Alumnos
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent classes={{ root: classes.dialogContent }}>
                {statusOperation === 'rejected' && (
                    <Error
                        onRetry={invalidateUI}
                        message={'Estamos teniendo problemas'}
                    />
                )}
                {statusOperation === 'pending' && (
                    <Stack spacing={2} sx={{ padding: 2 }}>
                        <Skeleton variant="rectangular" height={70} />
                        <Skeleton variant="rectangular" height={30} />
                        <Skeleton variant="rectangular" height={30} />
                    </Stack>
                )}

                {statusOperation === 'fulfilled' && (
                    <DataGrid
                        rows={students}
                        columns={columns}
                        getRowId={(row) => row.student_id}
                        rowHeight={100}
                        localeText={{
                            ...esES.components.MuiDataGrid.defaultProps
                                .localeText,
                            toolbarColumns: '',
                            toolbarFilters: '',
                            toolbarDensity: '',
                            toolbarExport: '',
                        }}
                        pageSize={20}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                        autoHeight
                        components={{
                            NoRowsOverlay: NoDataOverlay,
                            NoResultsOverlay: NoDataOverlay,
                            Toolbar: GridToolBar,
                            Pagination: CustomPagination,
                        }}
                        disableDensitySelector
                        getRowClassName={(GridRowParams) => {
                            if (GridRowParams.row.status === 0) {
                                return `super-app-theme--disabled`;
                            }
                        }}
                        componentsProps={{
                            noResultsOverlay: {
                                message:
                                    'No se encontraron resultados para la búsqueda',
                            },
                            noRowsOverlay: {
                                message: 'No hay Alumnos registrados',
                            },
                        }}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    color="primary"
                    loadingPosition="start"
                    size="small"
                    onClick={handleClose}
                    variant="contained"
                >
                    Cerrar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default RelationshipsModal;
