import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const municipalitiesAdapter = createEntityAdapter({
    selectId: (municipality) => municipality.municipality_id,
    sortComparer: (a, b) => a.municipality_id - b.municipality_id,
});

export const municipalitiesSlice = createSlice({
    name: 'municipalities',
    initialState: municipalitiesAdapter.getInitialState(),
    reducers: {
        addOneMunicipality: municipalitiesAdapter.addOne,
        addManyMunicipalities: municipalitiesAdapter.addMany,
        upsertManyMunicipalities: municipalitiesAdapter.upsertMany,
    },
});

export const {
    addOneMunicipality,
    addManyMunicipalities,
    upsertManyMunicipalities,
} = municipalitiesSlice.actions;

export const globalizedSelectors = municipalitiesAdapter.getSelectors(
    (state) => state.entities.municipalities
);

export const selectMunicipalitiesEntities = (state) =>
    globalizedSelectors.selectEntities(state);

export const selectAllMunicipañities = (state) =>
    globalizedSelectors.selectAll(state);

export default municipalitiesSlice.reducer;
