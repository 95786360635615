import React from 'react';
import { useHistory } from 'react-router-dom';
import { HowToReg, TipsAndUpdates } from '@mui/icons-material';
import { Button } from '@mui/material';

export const PreInscriptionMessage = ({ setShowContainer }) => {
    return (
        <div>
            <div class="flex items-center justify-center">
                <div className="flex items-center justify-center px-8 md:px-16 lg:px-32">
                    <p className="text-lg text-black text-center leading-relaxed">
                        <strong>¡Bienvenido al proceso de prescripción!</strong>
                        <br />
                        Nos alegra que estés considerando formar parte de
                        nuestra comunidad educativa. A través de nuestro sistema
                        de <strong>preinscripción</strong>, puedes asegurar tu
                        lugar y comenzar tu camino hacia una experiencia
                        educativa transformadora.
                        <br />
                        Recuerda que este es el primer paso para garantizar tu
                        acceso a una
                        <strong> educación de calidad </strong>, diseñada para
                        potenciar tus habilidades y ayudarte a alcanzar tus
                        metas.
                        <br />
                    </p>
                </div>
            </div>
            <div className="bottom-0 w-full flex items-center justify-center mt-5 space-x-4 pb-5">
                <Button
                    className="relative inline-flex items-center justify-center px-3.5 py-2.5 overflow-hidden font-medium text-green-600 transition duration-300 ease-out rounded-lg shadow-md bg-green-500 group capitalize"
                    onClick={() => setShowContainer('pre-inscribirte')}>
                    <span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                        <HowToReg className="w-6 h-6" />
                    </span>
                    <span className="absolute flex items-center text-base font-semibold justify-center w-full h-full text-white transition-all duration-300 transform group-hover:translate-x-full ease">
                        Pre inscribirte
                    </span>
                    <span className="relative text-base font-semibold invisible">
                        Pre inscribirte
                    </span>
                </Button>
                <Button
                    className="relative inline-flex items-center justify-center px-3.5 py-2.5 overflow-hidden font-medium text-orange-600 transition duration-300 ease-out rounded-lg shadow-md bg-orange-500 group capitalize"
                    onClick={() => setShowContainer('seguimiento')}>
                    <span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-orange-500 group-hover:translate-x-0 ease">
                        <TipsAndUpdates className="w-6 h-6" />
                    </span>
                    <span className="absolute flex items-center text-base font-semibold justify-center w-full h-full text-white transition-all duration-300 transform group-hover:translate-x-full ease">
                        Seguimiento
                    </span>
                    <span className="relative text-base font-semibold invisible">
                        Seguimiento
                    </span>
                </Button>
            </div>
        </div>
    );
};
