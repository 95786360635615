import React, { memo, useEffect, useState } from 'react';
import {
    TextField,
    Grid,
    Box,
    Typography,
    MenuItem,
    Divider,
    Autocomplete,
} from '@mui/material';
import Services from '../../../../../../service/Connection';

const PersonalInfoForm = memo(
    ({ formik, initialState, initialMunicipality, initialCity }) => {
        const [states, setStates] = useState([]);
        const [stateValue, setStateValue] = useState(null);
        const [municipalities, setMunicipalities] = useState([]);
        const [municipalityValue, setMunicipalityValue] = useState(null);
        const [cities, setCities] = useState([]);
        const [cityValue, setCityValue] = useState(null);
        const [contactData, setContactData] = useState({
            parent_name: '',
            parent_last_name: '',
            parent_second_last_name: '',
            parent_email: '',
            parent_phone: '',
            work_street: '',
            work_colony: '',
            work_external_number: '',
            work_internal_number: '',
            work_zipcode: '',
            parent_home_street: '',
            parent_home_external_number: '',
            parent_home_internal_number: '',
            parent_home_neighborhood: '',
            parent_home_zipcode: '',
            parent_birth_date: '',
            parent_gender: '',
            birth_city_id: '',
            state_id: '',
            municipality_id: '',
            relationship: '',
        });
        const [loadingMunicipalities, setLoadingMunicipalities] =
            useState(false);
        const [loadingCities, setLoadingCities] = useState(false);

        useEffect(() => {
            const getAllStates = async () => {
                const response = await Services.getStates();
                setStates(response.data.data);
            };
            getAllStates();
        }, []);

        useEffect(() => {
            if (initialState) {
                const selectedState = states.find(
                    (state) => state.state_id === initialState
                );
                setStateValue(selectedState);
            }
        }, [initialState, states]);

        useEffect(() => {
            if (stateValue) {
                const getMunicipalities = async () => {
                    const response = await Services.getMunicipalitysByStateId(
                        stateValue.state_id
                    );
                    setMunicipalities(response.data.data);
                };
                getMunicipalities();
            }
        }, [stateValue]);

        useEffect(() => {
            if (initialMunicipality && municipalities.length > 0) {
                const selectedMunicipality = municipalities.find(
                    (municipality) =>
                        municipality.municipality_id === initialMunicipality
                );
                setMunicipalityValue(selectedMunicipality);
            }
        }, [initialMunicipality, municipalities]);

        useEffect(() => {
            if (municipalityValue) {
                const getCities = async () => {
                    const response = await Services.getCityByMunicipalityId(
                        municipalityValue.municipality_id
                    );
                    setCities(response.data.data);
                };
                getCities();
            }
        }, [municipalityValue]);

        useEffect(() => {
            if (initialCity && cities.length > 0) {
                const selectedCity = cities.find(
                    (city) => city.city_id === initialCity
                );
                setCityValue(selectedCity);
            }
        }, [initialCity, cities]);

        const saveCity = (value) => {
            if (value !== null) {
                setCityValue(value);
                formik.setFieldValue('parent_birth_city_id', value.city_id);
            }
        };

        const getNoOptionsText = (field, isLoading) => {
            if (isLoading) return 'Cargando...';
            switch (field) {
                case 'state':
                    return 'Estado no encontrado';
                case 'municipality':
                    return 'Municipio no encontrado';
                case 'city':
                    return 'Ciudad no encontrada';
                default:
                    return 'No se encontraron opciones';
            }
        };

        return (
            <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 2 }}>
                <form onSubmit={formik.handleSubmit}>
                    <Divider>
                        <Typography variant="h5" align="center" gutterBottom>
                            Información Personal
                        </Typography>
                    </Divider>
                    <Typography
                        variant="body1"
                        align="center"
                        gutterBottom
                        sx={{ mb: 2, color: 'gray' }}
                    >
                        En este formulario, proporciona la información personal
                        básica del tutor, como su nombre completo, fecha de
                        nacimiento, género y datos de contacto. Estos datos son
                        esenciales para identificar al tutor y establecer una
                        comunicación adecuada.
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Nombre(s)*"
                                name="parent_name"
                                value={
                                    contactData.parent_name ||
                                    formik.values.parent_name
                                }
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setContactData({
                                        ...contactData,
                                        parent_name: e.target.value,
                                    });
                                }}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.parent_name &&
                                    Boolean(formik.errors.parent_name)
                                }
                                helperText={
                                    formik.touched.parent_name &&
                                    formik.errors.parent_name
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Apellido Paterno*"
                                name="parent_last_name"
                                value={
                                    contactData.parent_last_name ||
                                    formik.values.parent_last_name
                                }
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setContactData({
                                        ...contactData,
                                        parent_last_name: e.target.value,
                                    });
                                }}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.parent_last_name &&
                                    Boolean(formik.errors.parent_last_name)
                                }
                                helperText={
                                    formik.touched.parent_last_name &&
                                    formik.errors.parent_last_name
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Apellido Materno"
                                name="parent_second_last_name"
                                value={
                                    contactData.parent_second_last_name ||
                                    formik.values.parent_second_last_name
                                }
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setContactData({
                                        ...contactData,
                                        parent_second_last_name: e.target.value,
                                    });
                                }}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.parent_second_last_name &&
                                    Boolean(
                                        formik.errors.parent_second_last_name
                                    )
                                }
                                helperText={
                                    formik.touched.parent_second_last_name &&
                                    formik.errors.parent_second_last_name
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Email"
                                name="parent_email"
                                value={
                                    contactData.parent_email ||
                                    formik.values.parent_email
                                }
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setContactData({
                                        ...contactData,
                                        parent_email: e.target.value,
                                    });
                                }}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.parent_email &&
                                    Boolean(formik.errors.parent_email)
                                }
                                helperText={
                                    formik.touched.parent_email &&
                                    formik.errors.parent_email
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Celular*"
                                name="parent_phone"
                                value={
                                    contactData.parent_phone ||
                                    formik.values.parent_phone
                                }
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setContactData({
                                        ...contactData,
                                        parent_phone: e.target.value,
                                    });
                                }}
                                inputProps={{
                                    maxLength: 10,
                                    onKeyDown: (e) => {
                                        if (
                                            !/^\d$/.test(e.key) &&
                                            e.key !== 'Backspace'
                                        ) {
                                            e.preventDefault();
                                        }
                                    },
                                }}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.parent_phone &&
                                    Boolean(formik.errors.parent_phone)
                                }
                                helperText={
                                    formik.touched.parent_phone &&
                                    formik.errors.parent_phone
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Fecha de Nacimiento*"
                                name="parent_birth_date"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                value={
                                    (contactData.parent_birth_date &&
                                        new Date(contactData.parent_birth_date)
                                            .toISOString()
                                            .split('T')[0]) || //YYYY-MM-DD
                                    formik.values.parent_birth_date
                                }
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setContactData({
                                        ...contactData,
                                        parent_birth_date: e.target.value,
                                    });
                                }}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.parent_birth_date &&
                                    Boolean(formik.errors.parent_birth_date)
                                }
                                helperText={
                                    formik.touched.parent_birth_date &&
                                    formik.errors.parent_birth_date
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                select
                                fullWidth
                                label="Género*"
                                name="parent_gender"
                                value={
                                    contactData.parent_gender ||
                                    formik.values.parent_gender
                                }
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setContactData({
                                        ...contactData,
                                        parent_gender: e.target.value,
                                    });
                                }}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.parent_gender &&
                                    Boolean(formik.errors.parent_gender)
                                }
                                helperText={
                                    formik.touched.parent_gender &&
                                    formik.errors.parent_gender
                                }
                            >
                                <MenuItem value="1">Masculino</MenuItem>
                                <MenuItem value="2">Femenino</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                select
                                fullWidth
                                label="Relación/Parentesco*"
                                name="relationship"
                                value={
                                    contactData.relationship ||
                                    formik.values.relationship
                                }
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setContactData({
                                        ...contactData,
                                        relationship: e.target.value,
                                    });
                                }}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.relationship &&
                                    Boolean(formik.errors.relationship)
                                }
                                helperText={
                                    formik.touched.relationship &&
                                    formik.errors.relationship
                                }
                            >
                                <MenuItem value="1">Padre</MenuItem>
                                <MenuItem value="2">Madre</MenuItem>
                                <MenuItem value="3">Soy el alumno</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Calle*"
                                name="parent_home_street"
                                value={
                                    contactData.parent_home_street ||
                                    formik.values.parent_home_street
                                }
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setContactData({
                                        ...contactData,
                                        parent_home_street: e.target.value,
                                    });
                                }}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.parent_home_street &&
                                    Boolean(formik.errors.parent_home_street)
                                }
                                helperText={
                                    formik.touched.parent_home_street &&
                                    formik.errors.parent_home_street
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Número Exterior*"
                                name="parent_home_external_number"
                                value={
                                    contactData.parent_home_external_number ||
                                    formik.values.parent_home_external_number
                                }
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setContactData({
                                        ...contactData,
                                        parent_home_external_number:
                                            e.target.value,
                                    });
                                }}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched
                                        .parent_home_external_number &&
                                    Boolean(
                                        formik.errors
                                            .parent_home_external_number
                                    )
                                }
                                helperText={
                                    formik.touched
                                        .parent_home_external_number &&
                                    formik.errors.parent_home_external_number
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Número Interior"
                                name="parent_home_internal_number"
                                value={
                                    contactData.parent_home_internal_number ||
                                    formik.values.parent_home_internal_number
                                }
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setContactData({
                                        ...contactData,
                                        parent_home_internal_number:
                                            e.target.value,
                                    });
                                }}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched
                                        .parent_home_internal_number &&
                                    Boolean(
                                        formik.errors
                                            .parent_home_internal_number
                                    )
                                }
                                helperText={
                                    formik.touched
                                        .parent_home_internal_number &&
                                    formik.errors.parent_home_internal_number
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Colonia*"
                                name="parent_home_neighborhood"
                                value={
                                    contactData.parent_home_neighborhood ||
                                    formik.values.parent_home_neighborhood
                                }
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setContactData({
                                        ...contactData,
                                        parent_home_neighborhood:
                                            e.target.value,
                                    });
                                }}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.parent_home_neighborhood &&
                                    Boolean(
                                        formik.errors.parent_home_neighborhood
                                    )
                                }
                                helperText={
                                    formik.touched.parent_home_neighborhood &&
                                    formik.errors.parent_home_neighborhood
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Código Postal*"
                                name="parent_home_zipcode"
                                value={
                                    contactData.parent_home_zipcode ||
                                    formik.values.parent_home_zipcode
                                }
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setContactData({
                                        ...contactData,
                                        parent_home_zipcode: e.target.value,
                                    });
                                }}
                                inputProps={{
                                    maxLength: 5,
                                    inputMode: 'number',
                                    pattern: '[0-9]*',
                                }}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.parent_home_zipcode &&
                                    Boolean(formik.errors.parent_home_zipcode)
                                }
                                helperText={
                                    formik.touched.parent_home_zipcode &&
                                    formik.errors.parent_home_zipcode
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                value={stateValue}
                                isOptionEqualToValue={(option, value) =>
                                    option.state_id === value?.state_id
                                }
                                getOptionLabel={(option) => option.title || ''}
                                noOptionsText={getNoOptionsText('state', false)}
                                onChange={(event, newValue) => {
                                    setStateValue(newValue);
                                    formik.setFieldValue(
                                        'parent_birth_state_id',
                                        newValue ? newValue.state_id : ''
                                    );
                                    setMunicipalityValue(null);
                                    setCityValue(null);
                                    setMunicipalities([]);
                                    setCities([]);
                                }}
                                options={states}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Elige un estado"
                                        variant="outlined"
                                        name="parent_birth_state_id"
                                        error={
                                            formik.touched
                                                .parent_birth_state_id &&
                                            Boolean(
                                                formik.errors
                                                    .parent_birth_state_id
                                            )
                                        }
                                        helperText={
                                            formik.touched
                                                .parent_birth_state_id &&
                                            formik.errors.parent_birth_state_id
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                value={municipalityValue}
                                isOptionEqualToValue={(option, value) =>
                                    option.municipality_id ===
                                    value?.municipality_id
                                }
                                getOptionLabel={(option) => option.title || ''}
                                noOptionsText={getNoOptionsText(
                                    'municipality',
                                    loadingMunicipalities
                                )}
                                onChange={(event, newValue) => {
                                    setMunicipalityValue(newValue);
                                    formik.setFieldValue(
                                        'parent_birth_minicipality_id',
                                        newValue ? newValue.municipality_id : ''
                                    );
                                    setCityValue(null);
                                    setCities([]);
                                }}
                                options={municipalities}
                                disabled={municipalities.length === 0}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Elige un municipio"
                                        variant="outlined"
                                        name="parent_birth_minicipality_id"
                                        error={
                                            formik.touched
                                                .parent_birth_minicipality_id &&
                                            Boolean(
                                                formik.errors
                                                    .parent_birth_minicipality_id
                                            )
                                        }
                                        helperText={
                                            formik.touched
                                                .parent_birth_minicipality_id &&
                                            formik.errors
                                                .parent_birth_minicipality_id
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                value={cityValue}
                                isOptionEqualToValue={(option, value) =>
                                    option.city_id === value?.city_id
                                }
                                getOptionLabel={(option) => option.title || ''}
                                noOptionsText={getNoOptionsText(
                                    'city',
                                    loadingCities
                                )}
                                onChange={(event, newValue) => {
                                    saveCity(newValue);
                                }}
                                options={cities}
                                disabled={cities.length === 0}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Elige una ciudad"
                                        variant="outlined"
                                        name="parent_birth_city_id"
                                        error={
                                            formik.touched
                                                .parent_birth_city_id &&
                                            Boolean(
                                                formik.errors
                                                    .parent_birth_city_id
                                            )
                                        }
                                        helperText={
                                            formik.touched
                                                .parent_birth_city_id &&
                                            formik.errors.parent_birth_city_id
                                        }
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </form>
            </Box>
        );
    }
);

export default PersonalInfoForm;
