
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import {  useHistory } from 'react-router-dom';


export default function CloseDialog({ open, handleClose }) {
    const history = useHistory();
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'xs'}
            fullWidth={true}
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: 5,
                },
            }}
        >
            <DialogTitle
                id="alert-dialog-title"
                style={{
                    backgroundColor: '#2196f3',
                    textAlign: 'center',
                    fontSize: 20,
                }}
            >

                {"¡Preinscripción exitosa!"}
            </DialogTitle>
            <DialogContent
                sx={{
                    padding: 0,
                }}
            >
                <DialogContentText
                    sx={{
                        backgroundColor: '#fff3cd',
                        color: '#856404',
                        padding: 2,
                    }}
                >
                    <Typography variant="body1" sx={{ fontSize: 16 }}>
                        Por favor este pendiente al seguimiento.
                    </Typography>

                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Box sx={{ mb: 2 }}>
                    <Button
                        autoFocus
                        size="small"
                        color="error"

                        onClick={() => {
                            history.push({
                                pathname: '/',
                            });

                            handleClose()
                        }}
                    >
                        Cerrar
                    </Button>
                </Box>

            </DialogActions>
        </Dialog>
    )
}


