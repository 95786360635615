import React, { useEffect, useState } from 'react';
import { useFeedback } from '../../hooks';
import Connection from '../../service/Connection';
import Feedback from '../../service/Feedback';
import { Box, CircularProgress } from '@mui/material';

const OutsideBoot = (props) => {
    const { children } = props;
    const feedbackApp = useFeedback();

    const [loading, setLoading] = useState('idle');

    const fetchOutsideBootStart = async () => {
        setLoading('pending');
        try {
            let bootInfo = await Connection.getOutsideBootStart().then(
                (res) => res.data.preinscriptions
            );
            let activeLevels = bootInfo.levels.filter((level) => level.active);

            localStorage.setItem(
                'activeLevel',
                JSON.stringify(bootInfo.active)
            );
            localStorage.setItem('allLevels', JSON.stringify(bootInfo.levels));
            localStorage.setItem('activeLevels', JSON.stringify(activeLevels));
        } catch (err) {
            localStorage.setItem('activeLevel', false);
            let FeedbackService = new Feedback();
            feedbackApp.showFeedback({
                title:
                    FeedbackService.getMessage(err)?.message ||
                    'Error en la búsqueda',
            });
        }
        setLoading('fulfilled');
    };

    useEffect(() => {
        fetchOutsideBootStart();
    }, []);

    return (
        <>
            {loading === 'pending' && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <CircularProgress size={80} thickness={4.5} />
                </Box>
            )}
            {loading === 'fulfilled' && children}
        </>
    );
};

export default OutsideBoot;
