import React, { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    DialogContentText,
    Typography,
    List,
    ListItem,
    ListItemText,
    Divider,
    Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { LoadingButton } from '@mui/lab';
import { useAuth, useFeedback } from '../../../hooks';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { getLevelName, getTurnLevel } from '../../../libs/utils';
import {
    deleteGroupMiltiSubjects,
    selectRemoveGroupSubjectOperationStatus,
} from '../../../store/slices/groupsUI/operationsSlice';
import { selectGroupsById } from '../../../store/slices/entities/groups';
import ReportIcon from '@mui/icons-material/Report';

export default ({ open, setOpen, onDeleted, groupId, selectedIds }) => {
    const dispatch = useDispatch();
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;
    const feedbackApp = useFeedback();

    //////////// LOCAL STATES /////////////

    const [loading, setLoading] = useState(false);

    //////////// SELECTORES /////////////
    const group = useSelector(selectGroupsById(groupId));
    const config = useSelector(selectSettingBySchoolId(schoolId));
    const statusOperation = useSelector(
        selectRemoveGroupSubjectOperationStatus
    );

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    //////////////////// FUNCIONES /////////////////
    const handleClose = () => {
        setOpen(false);
    };

    const enviarDatos = () => {
        setLoading(true);
        if (!Array.isArray(selectedIds) || selectedIds.length === 0) {
            feedbackApp.showFeedback({
                title: 'No se han seleccionado materias para eliminar.',
            });
            return;
        }

        dispatch(
            deleteGroupMiltiSubjects({
                subjects: selectedIds,
                schoolId,
                groupId,
            })
        )
            .unwrap()
            .then((data) => {
                onDeleted(data);
                setLoading(false);
                setOpen(false);
                feedbackApp.showFeedback({
                    title: 'Materias eliminadas correctamente.',
                });
            })
            .catch(({ feedback }) => {
                setLoading(false);
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const subjectsDetails = selectedIds
        .map((subject) => {
            return subject
                ? {
                      title: subject?.catalog?.title,
                      folio: subject?.catalog?.folio,
                      grade: group?.grade,
                      group: group?.group,
                      level: getLevelName(group?.level),
                      turn: getTurnLevel(group?.turn),
                  }
                : null;
        })
        .filter((item) => item);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'xs'}
            fullWidth={true}
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: 5,
                },
            }}>
            <DialogTitle
                id="alert-dialog-title"
                style={{
                    backgroundColor: '#f44336',
                    textAlign: 'center',
                    fontSize: 20,
                }}
            />

            <DialogContent
                sx={{
                    padding: 0,
                }}>
                <DialogTitle
                    sx={{
                        padding: 2,
                    }}>
                    <Typography
                        variant="h6"
                        sx={{
                            color: '#f44336',
                            fontWeight: 'bold',
                        }}>
                        ¿Estás seguro?
                    </Typography>
                </DialogTitle>
                <DialogContentText
                    sx={{
                        backgroundColor: '#fff3cd',
                        color: '#856404',
                        padding: 2,
                    }}>
                    <Typography variant="body1" sx={{ fontSize: 16 }}>
                        Recuerda que esta acción no se puede deshacer y
                        eliminará las materias seleccionadas del grupo.
                    </Typography>
                </DialogContentText>

                <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                        padding: 2,
                    }}>
                    <DialogContentText
                        id="alert-dialog-description"
                        sx={{ textAlign: 'center' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <ReportIcon
                                sx={{
                                    color: '#f44336',
                                    fontSize: '3rem',
                                }}
                            />
                            <Typography variant="body1" sx={{ fontSize: 16 }}>
                                Las siguientes materias serán eliminadas del
                                grupo:
                            </Typography>
                        </Box>
                        <List
                            sx={{
                                maxHeight: 300,
                                overflowY: 'auto',
                                marginTop: 2,
                            }}>
                            {subjectsDetails.length === 0 ? (
                                <Typography
                                    variant="body2"
                                    color="textSecondary">
                                    No se encontraron materias.
                                </Typography>
                            ) : (
                                subjectsDetails.map((subject, index) => (
                                    <React.Fragment key={index}>
                                        <ListItem>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        variant="body2"
                                                        style={{
                                                            fontWeight: 'bold',
                                                        }}>
                                                        {subject.folio}°{' '}
                                                        {subject.title}
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography variant="body2">
                                                        {subject.grade}°{' '}
                                                        {subject.group} -{' '}
                                                        {subject.turn} -{' '}
                                                        {subject.level}
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                ))
                            )}
                        </List>
                    </DialogContentText>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box sx={{ mb: 2 }}>
                    <LoadingButton
                        variant="contained"
                        fullWidth
                        size="small"
                        color="error"
                        onClick={enviarDatos}
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<DeleteOutlineIcon />}>
                        Eliminar
                    </LoadingButton>
                </Box>
                <Box sx={{ mb: 2 }}>
                    <Button
                        onClick={handleClose}
                        autoFocus
                        size="small"
                        color="error"
                        disabled={loading}>
                        Cancelar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};
