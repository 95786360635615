import React from 'react';
import './App.scss';
import './chartist.min.css';

import { Provider } from 'react-redux';
import store from './store';
import { PersistGate } from 'redux-persist/integration/react';
import Dashboard from './components/Dashboard';
import AnimatedGate from './components/AnimatedGate';
import { ErrorBoundary } from 'react-error-boundary';
import GlobalErrorFallback from './components/GlobalErrorFallback';
import OutsideBoot from './components/OutsideBoot/OutsideBoot';

const storeI = store();

/**
 * Punto de entrata a a aplicacion
 *
 * @returns
 */
function App() {
    return (
        <OutsideBoot>
            <Provider store={storeI.store}>
                <ErrorBoundary
                    FallbackComponent={GlobalErrorFallback}
                    onReset={() => {
                        localStorage.clear();

                        window.location.href = '/';
                    }}
                >
                    <PersistGate persistor={storeI.persistor}>
                        {(bootstrapped) => (
                            <AnimatedGate bootstrapped={bootstrapped}>
                                <Dashboard />
                            </AnimatedGate>
                        )}
                    </PersistGate>
                </ErrorBoundary>
            </Provider>
        </OutsideBoot>
    );
}

export default App;
