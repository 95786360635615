import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const citiesAdapter = createEntityAdapter({
    selectId: (city) => city.city_id,
    sortComparer: (a, b) => a.city_id - b.city_id,
});

export const citiesSlice = createSlice({
    name: 'cities',
    initialState: citiesAdapter.getInitialState(),
    reducers: {
        addOneCity: citiesAdapter.addOne,
        addManyCities: citiesAdapter.addMany,
        upsertManyCities: citiesAdapter.upsertMany,
    },
});

export const { addOneCity, addManyCities, upsertManyCities } =
    citiesSlice.actions;

export const globalizedSelectors = citiesAdapter.getSelectors(
    (state) => state.entities.cities
);

export const selectCitiesEntities = (state) =>
    globalizedSelectors.selectEntities(state);

export const selectAllCities = (state) => globalizedSelectors.selectAll(state);

export default citiesSlice.reducer;
