import React, { useState } from 'react';
import { TextField, Grid, Box } from '@mui/material';
import GpsLocation from './GpsLocation';

const GpsLocationField = ({ longitude, latitude, setFieldValue }) => {
    const [gpsLocation, setGpsLocation] = useState(null);

    return (
        <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <GpsLocation
                        setLocation={(location) => {
                            setGpsLocation(location);
                            if (location) {
                                setFieldValue('longitude', location.longitude);
                                setFieldValue('latitude', location.latitude);
                                setFieldValue(
                                    'gpsLocation',
                                    `Lat: ${location.latitude}, Long: ${location.longitude}`
                                );
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Ubicación GPS"
                        name="gpsLocation"
                        value={
                            gpsLocation
                                ? `Lat: ${longitude}, Long: ${latitude}`
                                : ''
                        }
                        onChange={() => {}}
                        disabled
                        hidden
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default GpsLocationField;
