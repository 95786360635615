import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import Feedback from '../../../service/Feedback';
import Services from '../../../service/Connection';
import { upsertManyStates } from '../entities/states';
import { upsertManyMunicipalities } from '../entities/municipalities';
import { upsertManyCities } from '../entities/cities';
import { upsertManyTracesCatalogs } from '../entities/tracesCatalogs';
import { getExpireIn } from '../../../libs/utils';
import { upsertOneSchool } from '../entities/schools';

const emptyState = {
    fetch: {
        expireIn: null,
        fetchingAt: null,
        status: 'idle',
        didInvalidate: true,
        feedback: {
            title: null,
            message: null,
            payload: null,
        },
    },
};

export const bootSlice = createSlice({
    name: 'boot',
    initialState: emptyState,
    reducers: {
        invalidate: (state, action) => {
            state.boot.fetch.didInvalidate = true;
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });

        builder.addCase(loadBootUI.pending, (state, action) => {
            state.fetch.status = 'pending';
        });

        builder.addCase(loadBootUI.fulfilled, (state, action) => {
            state.fetch.status = 'fulfilled';
            state.fetch.fetchingAt = Date.now();
            state.fetch.didInvalidate = false;
            state.fetch.expireIn = getExpireIn('USERSUI_EXPIRE_TIME');
        });

        builder.addCase(loadBootUI.rejected, (state, action) => {
            state.fetch.status = 'rejected';
            state.fetch.feedback = action.payload.feedback;
        });
    },
});

export const { invalidate } = bootSlice.actions;

export default bootSlice.reducer;

/////////////////////// SELECTORS ///////////////////////

export const selectBoot = (state) => state.boot;

export const selectBootFetch = createSelector(selectBoot, (boot) => {
    return boot.fetch;
});

export const selectBootStatusOperations = createSelector(
    selectBootFetch,
    (fetch) => {
        return fetch.status;
    }
);

/////////////////// THUNKS /////////////////////////

export const loadBootUI = createAsyncThunk(
    'boot/load',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            let tracesCatalogs = await Services.getTracesCatalogs().then(
                (res) => res.data.data
            );
            let school = await Services.getSchoolById(schoolId).then(
                (res) => res.data.data
            );
            let municipality = await Services.getMunicipalityByCityId(
                school.city_id
            ).then((res) => res.data.data);
            let localState = await Services.getStateByMunicipalityID(
                municipality.municipality_id
            ).then((res) => res.data.data);
            let cities = await Services.getCitiesByState(
                localState.state_id
            ).then((res) => res.data.data);
            let municipalities = await Services.getMunicipalitysByStateId(
                localState.state_id
            ).then((res) => res.data.data);
            let states = await Services.getStates().then(
                (res) => res.data.data
            );

            thunkAPI.dispatch(upsertManyCities(cities));
            thunkAPI.dispatch(upsertManyMunicipalities(municipalities));
            thunkAPI.dispatch(upsertManyStates(states));
            thunkAPI.dispatch(upsertOneSchool(school));
            thunkAPI.dispatch(upsertManyTracesCatalogs(tracesCatalogs));

            return {
                states,
                cities,
                municipalities,
                tracesCatalogs,
                school,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (aeg, { getState, extra }) => {
            let { didInvalidate, expireIn } = getState().boot.fetch;

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
