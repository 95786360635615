import React, { createContext, useState, useContext } from 'react';

const ShowContainerContext = createContext();

export const ShowContainerProvider = ({ children }) => {
    const [showContainer, setShowContainer] = useState('inicio');

    return (
        <ShowContainerContext.Provider
            value={{ showContainer, setShowContainer }}>
            {children}
        </ShowContainerContext.Provider>
    );
};

export const useShowContainer = () => useContext(ShowContainerContext);
