import React, { useCallback, useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
} from '@mui/material';
import { useHistory, useLocation, Prompt } from 'react-router-dom';
import { Typography } from 'antd';
import WarningIcon from '@mui/icons-material/Warning';

const ConfirmLeaveModal = ({ hasUnsavedChanges }) => {
    const history = useHistory();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [nextLocation, setNextLocation] = useState(null);
    const [isNavigating, setIsNavigating] = useState(false);

    const handleBlockedNavigation = (nextLocation) => {
        if (!isNavigating && hasUnsavedChanges) {
            setOpen(true);
            setNextLocation(nextLocation);
            return false;
        }
        return true;
    };

    const handleCancel = () => {
        setOpen(false);
        setNextLocation(null);
    };

    const handleConfirm = () => {
        setOpen(false);
        setIsNavigating(true);
    };

    useEffect(() => {
        if (isNavigating && nextLocation) {
            history.push(nextLocation.pathname);
        }
    }, [isNavigating, nextLocation, history]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (hasUnsavedChanges) {
                event.preventDefault();
                event.returnValue = '';
                setOpen(true);
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [hasUnsavedChanges]);

    return (
        <>
            <Prompt
                when={hasUnsavedChanges}
                message={handleBlockedNavigation}
            />
            <Dialog
                open={open}
                sx={{
                    '& .MuiDialog-paper': {
                        borderRadius: 5,
                        width: '100%',
                        maxWidth: 400,
                        height: '100%',
                        maxHeight: 300,
                    },
                }}>
                <DialogTitle
                    sx={{
                        backgroundColor: 'warning.main',
                        textAlign: 'center',
                        fontSize: 20,
                    }}
                />

                <DialogContent>
                    <DialogTitle
                        sx={{
                            padding: '16px 0px',
                        }}>
                        <Typography variant="h6">¿Estás seguro?</Typography>
                    </DialogTitle>
                    <Box display="flex" alignItems="center">
                        <WarningIcon
                            sx={{
                                color: 'warning.main',
                                mr: 1,
                                fontSize: '4rem',
                            }}
                        />
                        <Typography variant="caption" sx={{ flexGrow: 1 }}>
                            No has confirmado los cambios realizados o no has
                            guardado la información, ¿estás seguro de que
                            quieres salir?
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ mb: 2 }}>
                        <Button
                            variant="contained"
                            color="warning"
                            fullWidth
                            size="small"
                            onClick={handleCancel}>
                            Cancelar
                        </Button>
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <Button color="warning" onClick={handleConfirm}>
                            Salir sin guardar
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ConfirmLeaveModal;
