import backgroundSlice from './slices/background';
import calendarSlice from './slices/calendar';
import auth from './slices/auth';
import materias from './slices/materias';
import groupsUI from './slices/groupsUI';
import reports from '../moduleReports/store/index';
import professorModule from './slices/professorModule';
import professorClassroom from './../Professors/store/index';
import noticesModule from './slices/noticesModule';

import { entities } from './slices/entities';
import settingsUISlice from './slices/settingsUI';
import usersUISlice from './slices/usersUI';
import { combineReducers } from '@reduxjs/toolkit';
import noticesModalUISlice from './slices/noticesModalUI';
import studentsUI from './slices/studentsUI';
import scoresUI from './../views/Scores/store/index';
import newReports from '../moduleReports/store/index2';
import paymentsModule from '../PaymentModule/store';
import moduleDirector from '../moduleDirector/store';
import theme from './slices/theme';
import notices from '../Notices/store/index';
import bootSlice from './slices/boot';

export default combineReducers({
    entities: entities,
    settingsUI: settingsUISlice,
    usersUI: usersUISlice,
    auth: auth,
    //professors,
    professorModule: professorModule,
    background: backgroundSlice,
    calendar: calendarSlice,
    subjectsUI: materias,
    groupsUI,
    reportsUI: reports,
    noticesModule: noticesModule,
    noticesModalUI: noticesModalUISlice,
    paymentsModule: paymentsModule,
    moduleDirector: moduleDirector,
    studentsUI,
    scoresUI,
    newReports,
    professorClassroom,
    theme,
    notices,
    boot: bootSlice,
});
