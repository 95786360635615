import {
    Box,
    Typography,
    TextField,
    Button,
    Tooltip,
    IconButton,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import React, { useState } from 'react';
import Connection from '../../../../../service/Connection';
import { LoadingButton } from '@mui/lab';
import { UploadFileTwoTone } from '@mui/icons-material';
import {
    Avatar,
    Card,
    Divider,
    message,
    Progress,
    Skeleton,
    Space,
} from 'antd';
import Meta from 'antd/lib/card/Meta';
import SchoolIcon from '../../../../assets/iconos/school-svg-com.svg';
import childrenIcon from '../../../../assets/iconos/children.svg';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Map, Marker } from 'pigeon-maps';
import { useFeedback } from '../../../../../hooks';
import { useShowContainer } from '../../../../services/Container';

const uuidRegex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
const phoneRegex = /^[0-9]{10}$/;

const validationSchema = Yup.object({
    identifier: Yup.string()
        .required('Campo requerido')
        .test(
            'is_valid',
            'Debe ser un Folio valido o número de teléfono válido',
            (value) => {
                return uuidRegex.test(value) || phoneRegex.test(value);
            }
        ),
});

export const Following = () => {
    /////////// SHARED STATE ///////////
    const feedbackApp = useFeedback();
    const { showContainer, setShowContainer } = useShowContainer();

    ///////////STATES////////////
    const [userData, setUserData] = useState(null);
    const [postulations, setPostulations] = useState([]);
    const [schools, setSchools] = useState([]);
    const [loading, setLoading] = useState(true);

    ///////////FUNCTION////////////
    const handleExportPDF = (postulationId) => {
        Connection.getPostulationsExportable('assignment-voucher', 'pdf', {
            id_postulacion: postulationId,
        })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `ficha_preinscripcion.pdf`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                feedbackApp.showFeedback({
                    title: '¡Ficha Descargada con Éxito! 🎉  Recuerda llevarla contigo a las instalaciones de la institución.',
                    severity: 'success',
                });
            })
            .catch((err) => {
                feedbackApp.showFeedback({
                    title: '¡Ups! Algo salió mal',
                    severity: 'error',
                });
            });
    };

    const handleSubmit = (values, { setSubmitting }) => {
        setUserData(null);
        setPostulations([]);
        setSchools([]);
        setLoading(true);

        let finalValues = {};
        if (uuidRegex.test(values.identifier)) {
            finalValues = `id_preinscripcion=${values.identifier}`;
        } else if (phoneRegex.test(values.identifier)) {
            finalValues = `cellphone=${values.identifier}`;
        }
        Connection.getPendingPrescriptionsById(finalValues)
            .then((response) => {
                setUserData(response.data.data);
                return Connection.getPendingPostulationsById(
                    response.data.data.preinscription_id,
                    finalValues
                );
            })
            .then((response) => {
                setPostulations(response.data.data);
                const postulationPromises = response.data.data.map(
                    (postulation) =>
                        Connection.getSchoolsByPostulations(
                            postulation.postulation_id,
                            finalValues
                        )
                );

                return Promise.all(postulationPromises);
            })
            .then((responses) => {
                const allSchools = responses.map(
                    (response) => response.data.data
                );
                setSchools(allSchools.flat());

                feedbackApp.showFeedback({
                    title: '¡Postulaciones Encontradas! 🎉  Es muy importante que mantengas los datos de tu preinscripción seguros.',
                    severity: 'success',
                });
            })
            .catch((err) => {
                feedbackApp.showFeedback({
                    title: '¡Ups! Algo salió mal, verifica tu folio o número de teléfono',
                    severity: 'error',
                });
            })
            .finally(() => {
                setSubmitting(false);
                setLoading(false);
            });
    };

    const getStatusPercentage = (status) => {
        switch (status) {
            case 1:
                return 25;
            case 2:
                return 100;
            default:
                return 0;
        }
    };

    const getStatusText = (status) => {
        switch (status) {
            case 1:
                return 'Por determinar';
            case 2:
                return 'Aceptada';
            default:
                return 'Desconocido';
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 1:
                return '#FFBF43';
            case 2:
                return '#00C483';
            default:
                return 'gray';
        }
    };

    return (
        <Box className="max-w-3xl mx-auto p-4">
            <Typography
                variant="h5"
                align="center"
                gutterBottom
                style={{ color: '#919399' }}>
                ¡Bienvenido/a al seguimiento de tu preinscripción! 🎉
            </Typography>
            <Divider />
            <Typography
                variant="subtitle1"
                gutterBottom
                className="py-2"
                style={{ color: '#757575', fontSize: '1.1rem' }}>
                - Debes de contar con tu{' '}
                <span
                    style={{
                        color: '#000',
                        fontWeight: 'bold',
                    }}>
                    Folio de preinscripcion{' '}
                </span>
                el cual obtuviste al momento de terminar el proceso de
                preinscripción.
                <br />- Esta pantalla es para checar el estatus de tu
                preinscripción.
            </Typography>

            <Divider />
            <Box className="mt-4">
                <Formik
                    initialValues={{ identifier: '' }}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}>
                    {({ errors, touched, dirty, isValid, isSubmitting }) => (
                        <Form>
                            <Box>
                                <Field
                                    name="identifier"
                                    as={TextField}
                                    variant="outlined"
                                    color="primary"
                                    label="Ingresa tu folio de seguimiento o número de teléfono"
                                    error={Boolean(
                                        errors.identifier && touched.identifier
                                    )}
                                    helperText={
                                        errors.identifier && touched.identifier
                                            ? errors.identifier
                                            : ''
                                    }
                                    fullWidth
                                    margin="normal"
                                    disabled={isSubmitting}
                                />
                            </Box>
                            <Box>
                                <LoadingButton
                                    loadingPosition="start"
                                    variant="outlined"
                                    color="primary"
                                    type="submit"
                                    loading={isSubmitting}
                                    disabled={
                                        isSubmitting || !dirty || !isValid
                                    }
                                    startIcon={<UploadFileTwoTone />}
                                    fullWidth
                                    className="mt-4">
                                    {isSubmitting ? 'Procesando...' : 'Enviar'}
                                </LoadingButton>
                            </Box>
                            <Box className="mt-4">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={() => setShowContainer('inicio')}>
                                    Regresar
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
            <Divider />

            <Space className="w-full" direction="vertical">
                {userData && (
                    <>
                        <Skeleton loading={loading} avatar active>
                            <Meta
                                avatar={<Avatar src={childrenIcon} />}
                                title={
                                    <Typography
                                        variant="h6"
                                        className="font-semibold">
                                        {userData.name} {userData.last_name}{' '}
                                        {userData.second_last_name}
                                    </Typography>
                                }
                                description={
                                    <Box>
                                        <Typography
                                            variant="body2"
                                            className="font-bold">
                                            Tu dirección:
                                        </Typography>
                                        <Typography variant="body2">
                                            {userData.home_street}, #
                                            {userData.home_external_number}
                                        </Typography>
                                        <Typography variant="body2">
                                            {userData.home_zipcode},{' '}
                                            {userData.home_neighborhood}
                                        </Typography>
                                    </Box>
                                }
                            />
                        </Skeleton>
                        <Divider />
                    </>
                )}
            </Space>
            <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}>
                {schools.length > 0 &&
                    schools.map((school, index) => {
                        const filteredPostulations = postulations.filter(
                            (postulation) =>
                                postulation.school_id === school.school_id
                        );
                        return (
                            <Card
                                key={index}
                                hoverable
                                style={{ width: 300, marginTop: 16 }}
                                loading={loading}
                                className="rounded-xl overflow-hidden shadow-lg
                                w-full"
                                cover={
                                    loading ? (
                                        <Skeleton.Image
                                            className="w-full"
                                            style={{ height: 400 }}
                                        />
                                    ) : (
                                        <>
                                            <Box className="absolute top-4 bg-white p-4 rounded-xl shadow-lg z-10 left-1/2 transform -translate-x-1/2 w-11/12 sm:w-4/5 md:w-3/4 lg:w-2/3 xl:w-1/2">
                                                <Typography
                                                    variant="body2"
                                                    className="font-bold"
                                                    align="center">
                                                    Dirección de la escuela
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    align="center">
                                                    {school.street}, #
                                                    {school.inside_number},{' '}
                                                    {school.zipcode},{' '}
                                                    {school.location}
                                                </Typography>
                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    mt={2}>
                                                    <Button
                                                        variant="text"
                                                        color="primary"
                                                        onClick={() => {
                                                            const url = `https://www.google.com/maps/dir/?api=1&destination=${school.latitude},${school.longitude}`;
                                                            window.open(
                                                                url,
                                                                '_blank'
                                                            );
                                                        }}>
                                                        Ver indicaciones de
                                                        llegada
                                                    </Button>
                                                </Box>
                                            </Box>
                                            <Map
                                                height={400}
                                                defaultCenter={[
                                                    school.latitude || 24.0226,
                                                    school.longitude ||
                                                        -104.6532,
                                                ]}
                                                mouseEvents={true}
                                                touchEvents={true}
                                                zoomSnap={true}
                                                zoomDelta={0}
                                                defaultZoom={15}>
                                                {school.longitude &&
                                                    school.latitude && (
                                                        <Marker
                                                            width={50}
                                                            anchor={[
                                                                school.latitude,
                                                                school.longitude,
                                                            ]}>
                                                            <img
                                                                src={SchoolIcon}
                                                                width={50}
                                                                height={50}
                                                                alt="marker"
                                                            />
                                                        </Marker>
                                                    )}
                                            </Map>
                                        </>
                                    )
                                }
                                actions={[
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        width="100%"
                                        key="actions">
                                        {filteredPostulations
                                            .filter(
                                                (postulation) =>
                                                    postulation.status === 2
                                            )
                                            .map((postulation) => (
                                                <Tooltip
                                                    key={
                                                        postulation.postulation_id
                                                    }
                                                    title="Descargar Ficha de Preinscripción en PDF"
                                                    arrow>
                                                    <IconButton
                                                        onClick={() =>
                                                            handleExportPDF(
                                                                postulation.postulation_id
                                                            )
                                                        }
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'column',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'center',
                                                            marginLeft: 2,
                                                            width: 'auto',
                                                            height: 'auto',
                                                            padding: 2,
                                                            borderRadius: 2,
                                                            transition:
                                                                'all 0.3s ease',
                                                            '&:hover': {
                                                                backgroundColor:
                                                                    'rgba(0, 0, 0, 0.1)',
                                                                borderRadius: 0,
                                                                width: 'auto',
                                                                height: 'auto',
                                                            },
                                                        }}>
                                                        <FileDownloadOutlinedIcon
                                                            style={{
                                                                width: 40,
                                                                height: 40,
                                                            }}
                                                        />
                                                        <Typography
                                                            variant="body2"
                                                            className="font-bold"
                                                            sx={{
                                                                marginTop: 1,
                                                            }}>
                                                            Descarga tu Ficha de
                                                            Preinscripción
                                                        </Typography>
                                                    </IconButton>
                                                </Tooltip>
                                            ))}
                                    </Box>,
                                ]}>
                                <Meta
                                    avatar={<Avatar src={SchoolIcon} />}
                                    title={
                                        <Typography
                                            variant="h6"
                                            className="font-bold">
                                            {school.name}
                                        </Typography>
                                    }
                                    description={school.address}
                                />
                                {filteredPostulations.map((postulation) => (
                                    <>
                                        <Divider />
                                        <Typography
                                            variant="h7"
                                            className="font-medium">
                                            Proceso de aceptación
                                        </Typography>
                                        <Box
                                            key={postulation.postulation_id}
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="flex-start"
                                            mt={2}>
                                            <Typography
                                                variant="body2"
                                                style={{
                                                    color: getStatusColor(
                                                        postulation.status
                                                    ),
                                                }}>
                                                {getStatusText(
                                                    postulation.status
                                                )}
                                            </Typography>

                                            <Progress
                                                percent={getStatusPercentage(
                                                    postulation.status
                                                )}
                                                showInfo={false}
                                                strokeColor={getStatusColor(
                                                    postulation.status
                                                )}
                                                style={{ width: '100%' }}
                                            />
                                        </Box>
                                    </>
                                ))}
                            </Card>
                        );
                    })}
            </Space>
        </Box>
    );
};
