import React from 'react';
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Box,
    Typography,
    Button,
} from '@mui/material';

export const StudentLevel = ({ data, setData, handleBack, handleNext }) => {
    const activeLevels = JSON.parse(localStorage.getItem('allLevels'));

    const handleChange = (event) => {
        setData({
            ...data,
            preinscriptionLevel: event.target.value,
        });
    };

    return (
        <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 2 }}>
            <Box className="header">
                <Box className="considerations">
                    <Typography
                        variant="subtitle1"
                        gutterBottom
                        style={{ color: '#919399' }}
                    >
                        <strong>
                            Antes de comenzar, ten en cuenta lo siguiente:
                        </strong>
                    </Typography>
                    <Typography variant="body2" style={{ color: '#919399' }}>
                        1. Documentos que pudieras necesitar: CURP, Acta de
                        nacimiento, Certificado de nivel anterior, Comprobante
                        de domicilio, boleta del grado anterior, recuerda que
                        los documentos son opcionales.
                    </Typography>
                    <Typography variant="body2" style={{ color: '#919399' }}>
                        2. Verifica que la información proporcionada sea
                        correcta.
                    </Typography>
                    <Typography variant="body2" style={{ color: '#919399' }}>
                        3. Si tienes alguna duda, consulta al soporte de CURA.
                    </Typography>
                </Box>
            </Box>
            <FormControl fullWidth variant="outlined" sx={{ mb: 4, mt: 4 }}>
                <InputLabel id="student-level-label">
                    Seleccionar nivel educativo
                </InputLabel>
                <Select
                    labelId="student-level-label"
                    value={data.preinscriptionLevel}
                    onChange={handleChange}
                    label="Seleccionar nivel educativo"
                    renderValue={(selected) => {
                        const selectedLevel = activeLevels.find(
                            (level) => level.value === selected
                        );
                        return selectedLevel ? (
                            <Box>
                                <Typography fontWeight="bold">
                                    {selectedLevel.level_name}
                                </Typography>
                                <Typography
                                    fontSize="12px"
                                    color="text.secondary"
                                >
                                    Fecha de Inicio: {selectedLevel.start},
                                    Fecha Final: {selectedLevel.end}, Días
                                    restantes: {selectedLevel.remain}
                                </Typography>
                            </Box>
                        ) : (
                            ''
                        );
                    }}
                >
                    <MenuItem value="">
                        <em>Seleccionar nivel</em>
                    </MenuItem>
                    {activeLevels.map((level) => (
                        <MenuItem
                            key={level.value}
                            value={level.value}
                            disabled={!level.active}
                        >
                            <Box>
                                <Typography fontWeight="bold">
                                    {level.level_name}
                                </Typography>
                                <Typography
                                    fontSize="12px"
                                    color="text.secondary"
                                >
                                    Fecha de Inicio: {level.start}, Fecha Final:{' '}
                                    {level.end}, Días restantes: {level.remain}
                                </Typography>
                            </Box>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 2,
                }}
            >
                <Button variant="outlined" onClick={handleBack}>
                    Regresar
                </Button>
                <Button
                    variant="contained"
                    disabled={data.preinscriptionLevel === ''}
                    onClick={handleNext}
                >
                    Siguiente
                </Button>
            </Box>
        </Box>
    );
};
