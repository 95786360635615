import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { validationSchema } from './components/validationSchema';
import { Box, Button } from '@mui/material';
import PersonalInfoForm from './components/PersonalInfoForm';
import AddressForm from './components/AddressForm';
import GpsLocationField from './components/GpsLocationField';

export const StudentParent = ({ data, setData, handleBack, handleNext }) => {
    const initialParent = data?.parent || {
        parent_name: '',
        parent_last_name: '',
        parent_second_last_name: '',
        parent_email: '',
        parent_phone: '',
        parent_birth_date: '',
        parent_gender: '',
        parent_birth_city_id: '',
        relationship: '',
        work_street: '',
        work_neighborhood: '',
        work_zipcode: '',
        work_external_number: '',
        work_internal_number: '',
        parent_birth_state_id: '',
        parent_birth_minicipality_id: '',
        addressCity: '',
    };
    const formik = useFormik({
        initialValues: {
            ...initialParent,
            longitude: data?.longitude || '',
            latitude: data?.latitude || '',
        },
        validateOnMount: true,
        validationSchema,
        onSubmit: (values) => {
            const updatedParent = {
                ...values,
            };

            setData({
                ...data,
                parent: updatedParent,
                longitude: values.longitude || '',
                latitude: values.latitude || '',
            });

            handleNext();
        },
    });

    useEffect(() => {
        const hasChanges =
            JSON.stringify(formik.values) !== JSON.stringify(data.parent);

        if (hasChanges) {
            setData({
                ...data,
                parent: formik.values,
                longitude: formik.values.longitude,
                latitude: formik.values.latitude,
            });
        }
    }, [formik.values, data, setData]);

    return (
        <>
            <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 2 }}>
                <form onSubmit={formik.handleSubmit}>
                    <PersonalInfoForm
                        formik={formik}
                        data={data}
                        initialParent={initialParent}
                        initialState={data?.parent?.parent_birth_state_id}
                        initialMunicipality={
                            data?.parent?.parent_birth_minicipality_id
                        }
                        initialCity={data?.parent?.parent_birth_city_id}
                    />
                    <AddressForm
                        formik={formik}
                        data={data}
                        initialParent={initialParent}
                    />
                    <GpsLocationField
                        longitude={formik.values.longitude}
                        latitude={formik.values.latitude}
                        setFieldValue={formik.setFieldValue}
                    />
                </form>
            </Box>
            <Box
                sx={{
                    maxWidth: 800,
                    margin: '0 auto',
                    padding: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                <Button variant="outlined" onClick={handleBack}>
                    Regresar
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    onClick={() => {
                        formik.validateForm().then((errors) => {
                            if (Object.keys(errors).length === 0) {
                                formik.handleSubmit();
                            } else {
                                console.log('Errores de validación:', errors);
                            }
                        });
                    }}
                    disabled={!formik.isValid}>
                    Siguiente
                </Button>
            </Box>
        </>
    );
};
