import React, { useState } from 'react';
import {
    Button,
    Switch,
    Card,
    CardActions,
    CardContent,
    Tooltip,
    CircularProgress,
} from '@mui/material';
import ModalUsuario from '../../components/Modal/ModalUsuarioAgregar';
import ModalUsuarioPassword from '../../components/Modal/ModalUsuarioPassword';
import {
    DataGrid,
    esES,
    GridActionsCellItem,
    GridToolbar,
} from '@mui/x-data-grid';

import { useDispatch, useSelector } from 'react-redux';
import { selectAdministersBySchool } from '../../store/slices/entities/users';
import EditIcon from '@mui/icons-material/Edit';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import {
    disableUser,
    selectAdministratorsView,
    selectUsersUI,
} from '../../store/slices/usersUI';
import ModalUsuarioCelular from '../../components/Modal/ModalUsuarioCelular';
import { useAuth } from '../../hooks';
import { useHistory } from 'react-router-dom';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AddIcon from '@mui/icons-material/Add';
import { SearchOutlined } from '@mui/icons-material';
import GridCellExpand from '../../components/CellContent';
import useFeedback from '../../hooks/useFeedback';
import { ModalEditUser } from '../../components/Modal/ModalEditUser';
import { ModalCreateUser } from '../../components/Modal/ModalCreateUser';
import FeatureFlags from '../../service/FeatureFlags';
import { AdminToolBar } from '../Usuarios/components/ProfesoresToolBar';
import AddCuraUserModal from '../Usuarios/components/AddCuraUserModal';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom';
import { updateFilter } from '../../store/slices/usersUI';
import NoDataOverlay from '../../components/utilities/NoDataOverlay';
import { GridToolBar } from '../../components/utilities/GridToolBar';
import CustomPagination from '../../components/utilities/CustomPagination';

/**
 * Pagina de administradores
 *
 * @returns
 */
const UsuariosAdministrador = () => {
    const dispatch = useDispatch();
    const Auth = useAuth();
    const history = useHistory();
    const feedbackApp = useFeedback();

    const match = useRouteMatch();
    const pathSegments = match.url.split('/');
    const tabKey = pathSegments[pathSegments.length - 1];
    const value = tabKey;

    ////////////////// SHARED STATE /////////////////

    const filtersUI = useSelector(selectUsersUI);

    const administrators = useSelector(selectAdministratorsView);

    const featureFlagCuraActiveUser = FeatureFlags.isFeatureFlagActive(
        'NEW_ACTIVE_CURA_USER'
    );

    ////////////////// LOCAL STATE  ////////////////

    const [openModal, setOpenModal] = useState(false);
    const [openModalNew, setOpenModalNew] = useState(false);
    const [openModalPassword, setOpenModalPassword] = useState(false);
    const [openModalCellphone, setOpenModalCellphone] = useState(false);
    const [openAddCuraUserModal, setOpenAddCuraUserModal] = useState(false);
    const [usuarioSelected, setUsuarioSelected] = useState([]);
    const [loadingRows, setLoadingRows] = useState({});

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },

        {
            field: 'enrollment',
            headerName: 'Matrícula',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'nombre',
            headerName: 'Nombre',
            flex: 1,
            minWidth: 250,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return `${params.row.last_name || ''} ${
                    params.row.second_last_name || ''
                } ${params.row.name || ''}`.replace('  ', ' ');
            },
        },
        {
            field: 'cellphone',
            headerName: 'Teléfono',
            flex: 0.5,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const FullCellphone = params.row.cellphone || ' ';
                return (
                    <div
                        style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
                    >
                        {FullCellphone}
                    </div>
                );
            },
        },
        {
            field: 'email',
            headerName: 'Correo',
            flex: 1,
            minWidth: 250,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'domicilio',
            headerName: 'Domicilio',
            flex: 1,
            minWidth: 250,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return `${params.row.colony || ' '} ${
                    params.row.street || ''
                } ${params.row.outside_number || ''} ${
                    params.row.zipcode || ''
                }`.replace('  ', ' ');
            },
            renderCell: (params) => {
                const value = `${params.row.colony || ' '} ${
                    params.row.street || ''
                }\n${params.row.outside_number || ''} C.P. ${
                    params.row.zipcode || ''
                }`;

                return (
                    <div
                        style={{
                            whiteSpace: 'pre-line',
                            textAlign: 'center',
                        }}
                    >
                        {value}
                    </div>
                );
            },
        },
        {
            field: 'status',
            headerName: 'Activo',
            type: 'singleSelect',
            flex: 0.3,
            minWidth: 50,
            headerAlign: 'center',
            align: 'center',
            valueOptions: ['Activo', 'Inactivo'],
            valueGetter: (params) => {
                return params.value === 1 ? 'Activo' : 'Inactivo';
            },
            renderCell: (params) => {
                const isLoading = loadingRows[params.row.id];

                return (
                    <Tooltip title="Desactivar/Activar usuario">
                        {isLoading ? (
                            <CircularProgress size={24} />
                        ) : (
                            <Switch
                                checked={params.row.status === 1 ? true : false}
                                onChange={(event) => {
                                    setUsuarioSelected(params.row);
                                    cambiarStatus(
                                        params.row,
                                        event.target.value
                                    );
                                }}
                                value={params.row.status}
                                disabled={
                                    isLoading ||
                                    Auth.getUserID(params.row) ===
                                        Auth.getUserID()
                                }
                            />
                        )}
                    </Tooltip>
                );
            },
        },
        {
            field: 'acciones',
            headerName: 'Acciones',
            sortable: false,
            flex: 0.4,
            minWidth: 50,
            headerAlign: 'center',
            align: 'center',
            type: 'actions',
            getActions: (params) => {
                if (Auth.getUserID(params.row) === Auth.getUserID()) {
                    return [
                        <GridActionsCellItem
                            icon={<AccountBoxIcon color="primary" />}
                            title="Perfil"
                            label="Perfil"
                            onClick={(event) => {
                                history.push({ pathname: `/perfil` });
                            }}
                            showInMenu
                        />,
                    ];
                }

                const actions = [
                    <GridActionsCellItem
                        icon={<EditIcon color="primary" />}
                        title="Editar"
                        label="Editar"
                        onClick={() => {
                            setUsuarioSelected(params.row);
                            setOpenModalNew(true);
                        }}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        icon={<VpnKeyIcon color="primary" />}
                        title="Cambiar contraseña"
                        label="Cambiar contraseña"
                        onClick={() => {
                            setUsuarioSelected(params.row);
                            setOpenModalPassword(true);
                        }}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        icon={<PhoneAndroidIcon color="primary" />}
                        title="Cambiar teléfono celular"
                        label="Cambiar teléfono celular"
                        onClick={() => {
                            setUsuarioSelected(params.row);
                            setOpenModalCellphone(true);
                        }}
                        showInMenu
                    />,
                ];

                return actions;
            },
        },
    ];

    /**
     * Cambiar el status de un usuario
     *
     * @param {*} info
     * @param {*} status
     */
    const cambiarStatus = async (info, status) => {
        const { id } = info;
        setLoadingRows((prev) => ({ ...prev, [id]: true }));
        let statusFinal = status === '1' ? 0 : 1;
        let data = {
            status: statusFinal,
        };

        try {
            await dispatch(
                disableUser({
                    user: info,
                    userId: info.user_id,
                    data,
                })
            )
                .unwrap()
                .then((response) => {
                    feedbackApp.showFeedback({
                        title: 'Usuario actualizado',
                    });
                });
        } catch ({ feedback }) {
            feedbackApp.showFeedback({
                title: feedback.title,
            });
        } finally {
            setLoadingRows((prev) => ({ ...prev, [id]: false }));
        }
    };

    const sortedDataGrid = administrators?.sort((a, b) => {
        const compareText = (propA, propB) => {
            const textA = propA.toUpperCase();
            const textB = propB.toUpperCase();
            if (textA < textB) {
                return -1;
            }
            if (textA > textB) {
                return 1;
            }
            return 0;
        };

        if (b.status !== a.status) {
            return b.status - a.status;
        }

        const lastNameComparison = compareText(a.last_name, b.last_name);
        if (lastNameComparison !== 0) {
            return lastNameComparison;
        }

        const secondLastNameComparison = compareText(
            a.second_last_name,
            b.second_last_name
        );
        if (secondLastNameComparison !== 0) {
            return secondLastNameComparison;
        }

        return compareText(a.name, b.name);
    });

    const dataGrid = sortedDataGrid?.map((row) => ({
        ...row,
        id: row.user_id,
    }));

    const showAddCuraUserModal = () => {
        setOpenAddCuraUserModal(true);
    };

    const handleFilterChange = (event) => {
        let newFilterValues = {};
        event.items.forEach((filter) => {
            const { columnField, operatorValue, value } = filter;

            newFilterValues = {
                columnField,
                operatorValue,
                value,
            };
        });
        dispatch(updateFilter({ value, filter: newFilterValues }));
    };

    const filterSetterValues = () => {
        const filtersForValue = filtersUI[value] || {};
        const filtersModified =
            filtersForValue &&
            (filtersForValue.columnField !== '' ||
                filtersForValue.operatorValue !== '' ||
                filtersForValue.value !== '');

        return {
            initialState: {
                filter: {
                    filterModel: (() => {
                        const items = [];
                        if (filtersModified) {
                            if (
                                filtersForValue.columnField &&
                                filtersForValue.operatorValue &&
                                filtersForValue.value
                            ) {
                                items.push({
                                    columnField: filtersForValue.columnField,
                                    operatorValue:
                                        filtersForValue.operatorValue,
                                    value: filtersForValue.value,
                                });
                            }
                        }
                        return { items };
                    })(),
                },
            },
        };
    };

    const OnChangeAddUser = () => {
        setUsuarioSelected(null);
        setOpenModal(true);
    };

    const anotherButtons = [
        featureFlagCuraActiveUser && {
            text: 'Usuario CURA',
            icon: <SearchOutlined />,
            onClick: showAddCuraUserModal,
        },
        {
            text: 'Agregar',
            icon: <AddIcon />,
            onClick: OnChangeAddUser,
        },
    ];

    ////////////////////// VIEW //////////////////////////

    return (
        <>
            <>
                <ModalEditUser
                    title="Administrador"
                    openModal={openModalNew}
                    setOpenModal={setOpenModalNew}
                    user={usuarioSelected}
                    tipoUsuario="ADMINISTER"
                />

                <ModalCreateUser
                    title="Administrador"
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    tipoUsuario="ADMINISTER"
                />
            </>
            {featureFlagCuraActiveUser && (
                <AddCuraUserModal
                    openModal={openAddCuraUserModal}
                    setOpenModal={setOpenAddCuraUserModal}
                />
            )}

            <ModalUsuarioPassword
                openModalPassword={openModalPassword}
                setOpenModalPassword={setOpenModalPassword}
                user={usuarioSelected}
            />

            <ModalUsuarioCelular
                openModal={openModalCellphone}
                setOpenModal={setOpenModalCellphone}
                user={usuarioSelected}
            />

            <Card
                sx={{
                    color: 'white',
                    borderRadius: '15px',
                    width: '100%',
                    height: '100%',
                }}
            >
                <DataGrid
                    localeText={{
                        ...esES.components.MuiDataGrid.defaultProps.localeText,
                        toolbarColumns: '',
                        toolbarFilters: '',
                        toolbarDensity: '',
                        toolbarExport: '',
                    }}
                    rows={dataGrid}
                    columns={columns}
                    pageSize={10}
                    disableSelectionOnClick
                    autoHeight
                    components={{
                        NoRowsOverlay: NoDataOverlay,
                        NoResultsOverlay: NoDataOverlay,
                        Toolbar: GridToolBar,
                        Pagination: CustomPagination,
                    }}
                    componentsProps={{
                        panel: {
                            placement: 'bottom-end',
                        },
                        toolbar: {
                            AnotherButtons: anotherButtons,
                        },
                        noResultsOverlay: {
                            message:
                                'No se encontraron resultados para la búsqueda',
                        },
                        noRowsOverlay: {
                            message: 'No hay usuarios registrados',
                        },
                    }}
                    onFilterModelChange={(event) => handleFilterChange(event)}
                    {...filterSetterValues()}
                    disableDensitySelector
                />
            </Card>
        </>
    );
};

export default UsuariosAdministrador;
