import React, { useEffect, useState } from 'react';

const GpsLocation = ({ setLocation }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [locationFetched, setLocationFetched] = useState(false);

    useEffect(() => {
        if (locationFetched) return;

        const fetchLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        setLocation({
                            latitude: latitude.toString(),
                            longitude: longitude.toString(),
                        });
                        setLocationFetched(true);
                        setLoading(false);
                    },
                    () => {
                        setError('No se pudo obtener la ubicación');
                        setLoading(false);
                    }
                );
            } else {
                setError(
                    'La geolocalización no está soportada en este navegador'
                );
                setLoading(false);
            }
        };

        fetchLocation();
    }, [locationFetched, setLocation]);

    // if (loading) return <div>Obteniendo ubicación...</div>;
    // if (error) return <div>{error}</div>;
    return null;
};

export default GpsLocation;
