import { Box, Button } from '@mui/material';
import React, { useState } from 'react';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import ArchiveIcon from '@material-ui/icons/Archive';
import FaceIcon from '@material-ui/icons/Face';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import SchoolIcon from '../../../../assets/iconos/school-svg-com.svg';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useHistory } from 'react-router-dom';

import CloseDialog from '../Summary/components/dialog'

import Connection from '../../../../../service/Connection'
import { useFeedback } from '../../../../../hooks';

import { Map, Marker } from "pigeon-maps"
import Feedback from '../../../../../service/Feedback';


const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    maxWidth: 800,
    color: theme.palette.text.primary,
}));

export const Summary = ({ data, handleBack, uuid }) => {

    console.log("data", data)

    const history = useHistory();
    const feedbackApp = useFeedback();
    let FeedbackService = new Feedback();

    const [checked, setChecked] = useState(true);

    const [open, setOpen] = useState(false);

    const handleOpen = () => { setOpen(true) };
    const handleClose = () => { setOpen(false) };

    const [isDisabled, setIsDisabled] = useState(true);

    const isEmptyObject = (obj) => { return obj && Object.keys(obj).length === 0; };

    const transformInitialLetter = (data) => { return data.replace(/\b\w/g, char => char.toUpperCase()); };

    const latitude = parseFloat(data.latitude);
    const longitude = parseFloat(data.longitude);

    const confirmPreInscription = async () => {

        let postulationsP = data.postulations.map(postulation => (
            {
                priority: postulation.priority,
                school_id: postulation.school.school_id,
                siblings_number: postulation.siblings_number
            }
        ));

        const preinscripcionData = { ...data, postulations: postulationsP, uuid: uuid };

        let report = "preinscription-voucher"
        let format = "pdf"

        try {
            await Connection.setPreRegistration(
                preinscripcionData
            ).then((res) => {

                const uuidresp = {
                    uuid: res.data.data.preinscription_id
                }

                Connection.getExportablePreinscription(
                    report,
                    format,
                    uuidresp
                ).then((resp) => {

                    const url = window.URL.createObjectURL(new Blob([resp.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `voucher_preincription.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    handleOpen()

                }).catch((error) => {
                    let feedbackError = FeedbackService.getMessage(error);

                    feedbackApp.showFeedback({
                        title: feedbackError.title,
                    });
                });


                feedbackApp.showFeedback({
                    title: 'preinscripcion hecha',
                });
            })
        } catch (error) {

            let feedbackError = FeedbackService.getMessage(error);

            feedbackApp.showFeedback({
                title: feedbackError.title,
            });
        }
    };

    const handleChange = (event) => {
        setChecked(event.target.checked);

        setIsDisabled(!isDisabled);
    };

    return (
        <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3 }}>

            <center><Typography variant="h3">Resumen</Typography></center>

            <StyledPaper
                sx={{
                    my: 1,
                    mx: 'auto',
                    p: 2,
                }}
            >
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                        <Avatar>
                            <FaceIcon />
                        </Avatar>
                    </Grid>
                    <Grid item xs zeroMinWidth>
                        <Typography noWrap>{"Alumno"}</Typography>

                        <ListItem>
                            <ListItemText primary="Nombre" secondary={transformInitialLetter(data.student?.name)} />
                            <ListItemText primary="Apellidos" secondary={data.student.last_name + ' ' + data.student.second_last_name} />


                            <ListItemText primary="Curp" secondary={data.student.curp} />
                        </ListItem>

                        <ListItem>
                            <ListItemText primary="Colonia" secondary={data.student.home_neighborhood} />
                            <ListItemText primary="Calle" secondary={data.student.home_street} />
                            <ListItemText primary="Numero interior" secondary={data.student.home_internal_number} />
                            <ListItemText primary="Numero exterior" secondary={data.student.home_external_number} />
                        </ListItem>

                        <ListItem>
                            <ListItemText primary="Fecha de nacimiento" secondary={data.student.birth_date} />
                            <ListItemText primary="Medida" secondary={data.student.size} />
                            <ListItemText primary="Codigo postal" secondary={data.student.home_zipcode} />
                        </ListItem>
                    </Grid>
                </Grid>
            </StyledPaper>

            <StyledPaper
                sx={{
                    my: 1,
                    mx: 'auto',
                    p: 2,
                }}
            >
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                        <Avatar>
                            <AccountCircleIcon />
                        </Avatar>
                    </Grid>
                    <Grid item xs>
                        <Typography noWrap>{"Contacto"}</Typography>

                        <List >
                            <ListItem>
                                <ListItemText primary="Nombre" secondary={transformInitialLetter(data.parent.parent_name)} />
                                <ListItemText primary="Apellidos" secondary={data.parent.parent_last_name + ' ' + data.parent.parent_second_last_name} />
                                <ListItemText primary="Correo" secondary={data.parent.parent_email} />
                                <ListItemText primary="Fecha de nacimiento" secondary={data.parent.parent_birth_date} />
                                <ListItemText primary="Telefono" secondary={data.parent.parent_phone} />
                            </ListItem>

                            <ListItem>
                                <ListItemText primary="Calle" secondary={data.parent.parent_home_street} />
                                <ListItemText primary="Colonia" secondary={data.parent.parent_home_neighborhood} />
                                <ListItemText primary="Numero interior" secondary={data.parent.parent_home_internal_number} />
                                <ListItemText primary="Numero exterior" secondary={data.parent.parent_home_external_number} />
                                <ListItemText primary="Codigo postal" secondary={data.parent.parent_home_external_number} />
                            </ListItem>

                            <ListItem>
                                <ListItemText primary="Calle (trabajo)" secondary={data.parent.work_street} />
                                <ListItemText primary="Colonia (trabajo)" secondary={data.parent.work_neighborhood} />
                                <ListItemText primary="Numero interior(trabajo)" secondary={data.parent.work_external_number} />
                                <ListItemText primary="Numero exterior(trabajo)" secondary={data.parent.work_internal_number} />
                                <ListItemText primary="Codigo postal(trabajo)" secondary={data.parent.work_zipcode} />
                            </ListItem>
                        </List>

                        {/* {data.parent.contact} */}
                    </Grid>
                </Grid>
            </StyledPaper>

            <StyledPaper
                sx={{
                    my: 1,
                    mx: 'auto',
                    p: 2,
                }}
            >
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                        <Avatar>
                            <ArchiveIcon />
                        </Avatar>
                    </Grid>
                    <Grid item xs>
                        <Typography>{"Documentos"}</Typography>

                        <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                            {Object.keys(data.archives).length > 0 ? (
                                Object.keys(data.archives).map((key) => (
                                    <Chip label={`${key}.pdf`} key={key} />
                                ))
                            ) : (
                                <Typography align="center">
                                    Documentos no cargados
                                </Typography>
                            )}
                        </Stack>

                        {/* {data.archives ? data.archives.length : 0} */}
                    </Grid>
                </Grid>
            </StyledPaper>

            <StyledPaper
                sx={{
                    my: 1,
                    mx: 'auto',
                    p: 2,
                }}
            >
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                        <Avatar>

                        </Avatar>
                    </Grid>
                    <Grid item xs>
                        <Typography>{"Postulaciónes"}</Typography>

                        <Grid container spacing={2} >
                            {data.postulations.map((postulation, index) =>
                            (<Grid item xs={12} sm={6} md={4} key={index} >
                                <Card sx={{ width: '100%' }} >
                                    <CardActionArea>
                                        <center>
                                            <Avatar>
                                                <AccountBalanceIcon />
                                            </Avatar>
                                        </center>
                                        <CardContent style={{ padding: 0 }}>
                                            <center>
                                                <Typography component="div">
                                                    {postulation.school.name}
                                                </Typography>

                                                <Typography>
                                                    Direccion: {postulation.school.colony}
                                                </Typography>

                                                <Typography>
                                                    Codigo Postal: {postulation.school.zip_code}
                                                </Typography>

                                                <Typography>
                                                    Estado: {postulation.school.state}
                                                </Typography>

                                                <Typography>
                                                    Telefono: {postulation.school.phone}
                                                </Typography>
                                            </center>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container wrap="nowrap">
                    <Map
                        height={400}
                        defaultCenter={[
                            latitude, longitude
                        ]}
                        defaultZoom={11}
                    >
                        {data.postulations.map((postulation, index) =>
                        (
                            <Marker
                                width={50}
                                anchor={[
                                    postulation.school.latitude, postulation.school.longitude
                                ]}
                            >
                                <img
                                    src={SchoolIcon}
                                    width={50}
                                    height={50}
                                    alt="marker"
                                />
                            </Marker>
                        ))}
                    </Map>
                </Grid>
            </StyledPaper>

            <center>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2, maxWidth: 800 }}>
                    <Button type="default" onClick={handleBack}>
                        Regresar
                    </Button>

                    <Button
                        size="small"
                        sx={{
                            color: 'black',
                            textDecoration: 'underline',
                            fontWeight: 'bold',
                            padding: '0',
                        }}
                        target="_blank"
                        href={"/TerminosCondiciones"}
                    >
                        Términos y condiciones
                    </Button>

                    <FormControlLabel required control={<Checkbox onChange={handleChange} />} label="Aceptar terminos y condiciones" />

                    <Button
                        type="primary"
                        variant="contained"
                        onClick={confirmPreInscription}
                        disabled={isDisabled}
                    >
                        Finalizar
                    </Button>
                </Box>
            </center>

            <CloseDialog
                open={open} handleClose={handleClose}
            ></CloseDialog>

        </Box>
    )
}