import React, { useEffect, useState } from 'react';
import {
    Button,
    Box,
    Card,
    CardContent,
    CardActions,
    Tooltip,
    Switch,
    CircularProgress,
} from '@mui/material';
import AddSubjectsModal from './components/AddSubjectsModal';
import ListGroupsModal from './components/ListGroupsModal';
import ListProfessorsModal from './components/ListProfessorsModal';
import { DataGrid, esES, GridActionsCellItem } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectStatusServer,
    loadUI,
    invalidate,
    updateCatalogSubject,
    selectSubjectsFilterUI,
} from '../../store/slices/materias';
import { useAuth } from '../../hooks';
import AddIcon from '@mui/icons-material/Add';
import { Error } from '../../components/Feedback';
import { useHistory } from 'react-router-dom';
import { getLevelName } from '../../libs/utils';
import ListIcon from '@mui/icons-material/List';
import EditIcon from '@mui/icons-material/Edit';
import { selectSubjectsCatalogAsArray } from '../../store/slices/entities/subjectcatalogs';
import SyncIcon from '@mui/icons-material/Sync';
import SkeletonPage from './components/SkeletonPage';
import useFeedback from '../../hooks/useFeedback';
import { SCHOOL_LEVELS } from '../../constants';
import {
    selectColumnField,
    selectOperatorValue,
    selectValue,
} from '../../store/slices/materias';
import NoDataOverlay from '../../components/utilities/NoDataOverlay';
import { GridToolBar } from '../../components/utilities/GridToolBar';
import CustomPagination from '../../components/utilities/CustomPagination';

/**
 * Pagina para mostrar las materias
 *
 * @returns
 */
const Materias = () => {
    const history = useHistory();
    const Auth = useAuth();
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    ////////////// SHARED STATE -//////////////////

    const statusServer = useSelector(selectStatusServer);
    const materias = useSelector(selectSubjectsCatalogAsArray());
    const filters = useSelector(selectSubjectsFilterUI);
    const filter = filters.ui;

    //////////////// LOCAL STATE /////////////////

    const [openModal, setOpenModal] = useState(false);
    const [openModalGrupos, setOpenModalGrupos] = useState(false);
    const [openProfessorsModal, setOpenProfessorsModal] = useState(false);
    const [catalogSelected, setCatalogSelected] = useState(null);
    const [subjectCatalogId, setSubjectCatalogId] = useState(null);
    const [loadingRows, setLoadingRows] = useState({});

    /**
     * Muestra el modal para actualziar materia
     *
     * @param {*} data
     */
    const actualizar = (data) => {
        setCatalogSelected(data);
        setOpenModal(true);
    };

    const columns = [
        {
            field: 'folio',
            headerName: 'Folio',
            flex: 1,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const FullFolio = `${params.row.folio}`;
                return (
                    <div
                        style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
                    >
                        {FullFolio}
                    </div>
                );
            },
        },
        {
            field: 'title',
            headerName: 'Materia',
            flex: 1,
            minWidth: 120,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const FullTitle = `${params.row.title}`;
                return (
                    <div
                        style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
                    >
                        {FullTitle}
                    </div>
                );
            },
        },
        {
            field: 'level',
            headerName: 'Nivel',
            type: 'singleSelect',
            flex: 1,
            minWidth: 120,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueOptions: SCHOOL_LEVELS.map((i) => i.title),
            valueGetter: (params) => {
                return getLevelName(params.row.level);
            },
        },
        {
            field: 'groups',
            headerName: 'Grupos',
            flex: 1,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Tooltip title="Mostrar grupos">
                    <Button
                        variant="outlined"
                        size="small"
                        startIcon={<ListIcon />}
                        onClick={() => {
                            setSubjectCatalogId(params.id);
                            setOpenModalGrupos(true);
                        }}
                    >
                        {params.value}
                    </Button>
                </Tooltip>
            ),
        },
        {
            field: 'professors',
            headerName: 'Maestros',
            flex: 1,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Tooltip title="Mostrar maestros">
                    <Button
                        variant="outlined"
                        size="small"
                        startIcon={<ListIcon />}
                        onClick={() => {
                            setSubjectCatalogId(params.id);
                            setOpenProfessorsModal(true);
                        }}
                    >
                        {params.value}
                    </Button>
                </Tooltip>
            ),
        },
        {
            field: 'status',
            headerName: 'Para Promedio',
            type: 'singleSelect',
            flex: 0.3,
            minWidth: 150,
            headerAlign: 'center',
            align: 'center',
            valueOptions: ['Activo', 'Inactivo'],
            valueGetter: (params) => {
                return params.row.is_discarted === false
                    ? 'Activo'
                    : 'Inactivo';
            },
            renderCell: (params) => {
                const isLoading = loadingRows[params.row.id];

                return (
                    <Tooltip title="Validar materia para promedio">
                        {isLoading ? (
                            <CircularProgress size={24} />
                        ) : (
                            <Switch
                                checked={!params.row.is_discarted}
                                onChange={(event) => {
                                    cambiarStatus(
                                        params.row,
                                        event.target.value
                                    );
                                }}
                                value={params.row.is_discarted}
                                disabled={
                                    isLoading ||
                                    Auth.getUserID(params.row) ===
                                        Auth.getUserID()
                                }
                            />
                        )}
                    </Tooltip>
                );
            },
        },
        {
            field: 'acciones',
            headerName: 'Acciones',
            sortable: false,
            flex: 0.5,
            minWidth: 90,
            headerAlign: 'center',
            align: 'center',
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<EditIcon color="primary" />}
                    title="Editar"
                    label="Editar"
                    onClick={() => {
                        actualizar(params.row);
                    }}
                    showInMenu
                />,
            ],
        },
    ];

    const dataGrid = materias?.map((row) => ({
        id: row.catalog_subject_id,
        ...row,
    }));

    const filteredDataGrid = dataGrid.filter(
        (row) => row && row.level && row.title && row.folio
    );

    const orderedDataGrid = filteredDataGrid
        .slice()
        .sort((subjectA, subjectB) => {
            const levelComparison = subjectA.level
                .toString()
                .localeCompare(subjectB.level.toString());
            if (levelComparison !== 0) {
                return levelComparison;
            }

            const titleComparison = subjectA.title.localeCompare(
                subjectB.title
            );
            if (titleComparison !== 0) {
                return titleComparison;
            }

            return subjectA.folio.localeCompare(subjectB.folio);
        });

    /////////////////////// ACTIONS ////////////////////

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate());
        reload();
    };

    /**
     * Funcion para recargar los datos del usuario
     */
    const reload = () => {
        dispatch(loadUI(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const cambiarStatus = async (subject, status) => {
        const { id } = subject;
        setLoadingRows((prev) => ({ ...prev, [id]: true }));

        let statusFinal = status === 'false' ? true : false;

        try {
            await dispatch(
                updateCatalogSubject({
                    catalogSubjectId: subject.id,
                    catalog: { is_discarted: statusFinal },
                })
            ).unwrap();

            feedbackApp.showFeedback({
                title: 'Materia actualizada',
            });
        } catch ({ feedback }) {
            feedbackApp.showFeedback({
                title: feedback.title,
            });
        } finally {
            setLoadingRows((prev) => ({ ...prev, [id]: false }));
        }
    };

    /**
     * Si el usuario a entrado a la aplicacion
     * iniciamos el proceso de recuperacion de datos
     *
     */
    useEffect(() => {
        dispatch(loadUI(Auth.getUser().school_id));
    }, []);

    const handleFilterChange = (event) => {
        let newFilterValues = {};

        event.items.forEach((filter) => {
            const { columnField, operatorValue, value } = filter;

            newFilterValues = {
                columnField,
                operatorValue,
                value,
            };
        });
        dispatch(selectColumnField(newFilterValues.columnField));
        dispatch(selectOperatorValue(newFilterValues.operatorValue));
        dispatch(selectValue(newFilterValues.value));
    };

    const handleOpenModal = () => {
        setCatalogSelected(null);
        setOpenModal(true);
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}
        >
            {openModal && (
                <AddSubjectsModal
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    catalog={catalogSelected}
                />
            )}

            {openModalGrupos && (
                <ListGroupsModal
                    openModal={openModalGrupos}
                    setOpenModal={setOpenModalGrupos}
                    subjectCatalogId={subjectCatalogId}
                />
            )}

            {openProfessorsModal && (
                <ListProfessorsModal
                    openModal={openProfessorsModal}
                    setOpenModal={setOpenProfessorsModal}
                    subjectCatalogId={subjectCatalogId}
                />
            )}

            {statusServer == 'pending' && <SkeletonPage />}

            {statusServer == 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {statusServer == 'fulfilled' && (
                <>
                    <Box
                        style={{
                            marginBottom: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Tooltip title="Sincronizar información">
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={invalidateUI}
                                startIcon={<SyncIcon />}
                            >
                                Sincronizar
                            </Button>
                        </Tooltip>
                    </Box>

                    <Card
                        sx={{
                            mt: 5,
                            color: 'white',
                            borderRadius: '15px',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <DataGrid
                            localeText={{
                                ...esES.components.MuiDataGrid.defaultProps
                                    .localeText,
                                toolbarColumns: '',
                                toolbarFilters: '',
                                toolbarDensity: '',
                                toolbarExport: '',
                            }}
                            rows={orderedDataGrid}
                            columns={columns}
                            rowHeight={70}
                            pageSize={10}
                            rowsPerPageOptions={[5]}
                            disableSelectionOnClick
                            autoHeight
                            components={{
                                NoRowsOverlay: NoDataOverlay,
                                NoResultsOverlay: NoDataOverlay,
                                Toolbar: GridToolBar,
                                Pagination: CustomPagination,
                            }}
                            componentsProps={{
                                toolbar: {
                                    onAddButtonClick: handleOpenModal,
                                    ButtonText: 'Agregar',
                                },
                                noResultsOverlay: {
                                    message:
                                        'No se encontraron resultados para la búsqueda',
                                },
                                noRowsOverlay: {
                                    message: 'No hay Materias registrados',
                                },
                            }}
                            onFilterModelChange={handleFilterChange}
                            initialState={{
                                filter: {
                                    filterModel: {
                                        items:
                                            filter.columnField &&
                                            filter.operatorValue &&
                                            filter.value
                                                ? [
                                                      {
                                                          columnField:
                                                              filter.columnField,
                                                          operatorValue:
                                                              filter.operatorValue,
                                                          value: filter.value,
                                                      },
                                                  ]
                                                : [],
                                    },
                                },
                            }}
                            disableDensitySelector
                        />
                    </Card>
                </>
            )}
        </Box>
    );
};

export default Materias;
