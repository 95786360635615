import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const statesAdapter = createEntityAdapter({
    selectId: (states) => states.state_id,
    sortComparer: (a, b) => a.state_id - b.state_id,
});

export const statesSlice = createSlice({
    name: 'states',
    initialState: statesAdapter.getInitialState(),
    reducers: {
        addOneState: statesAdapter.addOne,
        addManyStates: statesAdapter.addMany,
        upsertManyStates: statesAdapter.upsertMany,
    },
});

export const { addOneState, addManyStates, upsertManyStates } =
    statesSlice.actions;

export const globalizedSelectors = statesAdapter.getSelectors(
    (state) => state.entities.states
);

export const selectStatesEntities = (state) =>
    globalizedSelectors.selectEntities(state);

export const selectAllStates = (state) => globalizedSelectors.selectAll(state);

export default statesSlice.reducer;
