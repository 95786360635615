import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import Connection from '../../../service/Connection';
import Feedback from '../../../service/Feedback';
import { upsertManyRelationships } from '../../../store/slices/entities/relationships';
import { upsertManyPartialities } from '../../../store/slices/entities/payments/partialities';
import { PAYMENTS_HOME_EXPIRE_TIME } from '../../../service/const';
import { upsertManyUsers } from '../../../store/slices/entities/users';
import { upsertManyConcepts } from '../../../store/slices/entities/payments/concepts';

// Estado inicial del slice
const emptyState = {
    expireIn: null,
    fetchingAt: null,
    status: 'idle',
    operation: 'idle',
    didInvalidate: true,
    feedback: {
        title: null,
        message: null,
        payload: null,
    },
};

// Slice para manejar el estado de la petición de ingresos
const PartialitieSlice = createSlice({
    name: 'partialitie',
    initialState: emptyState,
    reducers: {
        invalidate: (state, action) => {
            state.didInvalidate = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });

        builder

            .addCase(fetchPartialities.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(fetchPartialities.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                state.expireIn = new Date().setMinutes(
                    new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
                );
                state.fetchingAt = Date.now();
                state.didInvalidate = false;

                state.status = 'fulfilled';
            })
            .addCase(fetchPartialities.rejected, (state, action) => {
                state.status = 'rejected';
                state.feedback = action.payload.feedback;
            });
    },
});

export const { invalidate: invalidatePartialitie } = PartialitieSlice.actions;

export default PartialitieSlice.reducer;

// //////////////////////////////////// THUNKS // ////////////////////////////////////

export const fetchPartialities = createAsyncThunk(
    'partialities/fetch',
    async (conceptId, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            const [fetchPartialitie] = await Promise.all([
                Connection.getPartialitiesByConcept(conceptId),
            ]);

            const partialities = fetchPartialitie.data.data;

            thunkAPI.dispatch(upsertManyPartialities(partialities));

            return {
                partialities,
            };
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

// Thunk para realizar la petición asincrónica de relaciones

export const selectPartialities = (state) => state.entities.partialities;
