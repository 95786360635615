import { Button } from '@mui/material';
import React from 'react';
import { NavLink, useHistory } from 'react-router-dom/cjs/react-router-dom.min';

//components
import Downloads from './Downloads';

//ICONS
import whatsapp from '../assets/whatsapp.svg';
import whatsAppFloat from '../assets/whatsAppFloat.svg';
import youtube from '../assets/youtube.svg';
import facebook from '../assets/facebook.svg';
import directivo from '../assets/directivo.png';
import instagram from '../assets/instagram.svg';
import palomita from '../assets/palomita.svg';
import logoFooter from '../assets/logo_footer.svg';

//STYLES
import '../style.css';

export const FooterInfo = () => {
    const history = useHistory();

    const handleClick = () => {
        window.scrollTo(0, 0);
    };

    return (
        <div className="mweb">
            <section className="descargas">
                <Downloads />
            </section>
            <footer>
                <img className="palomita" src={palomita} alt="" />
                <div className="custom-container">
                    <div className="infoot">
                        <div>
                            <img src={logoFooter} alt="" />
                            <p>
                                Transformando la manera en que Maestros, Padres
                                de familia y Directores gestionan la educación.
                            </p>
                        </div>

                        <div className="profe">
                            <img
                                src={directivo}
                                style={{ width: '230px' }}
                                alt="directivo"
                            />
                        </div>
                        <div className="FBeneficios">
                            <h4>Visita también:</h4>
                            <ul>
                                <li>
                                    <NavLink
                                        to="/beneficios"
                                        onClick={handleClick}>
                                        <a>Beneficios</a>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/funcionalidades"
                                        onClick={handleClick}>
                                        <a>Funcionaliddes</a>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <ul className="redes">
                                <li>
                                    <a
                                        href="https://www.facebook.com/curaeducacion"
                                        target="_blank">
                                        <img src={facebook} alt="" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.instagram.com/curaeducacion/"
                                        target="_blank">
                                        <img src={instagram} alt="" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.youtube.com/@cura-educacionefectiva-oficial"
                                        target="_blank">
                                        <img src={youtube} alt="" />
                                    </a>
                                </li>
                            </ul>
                            <a
                                href="https://wa.me/5216181448945"
                                className="float-wa"
                                target="_blank">
                                <img
                                    src={whatsAppFloat}
                                    className="wa-icon"
                                    alt=""
                                />
                            </a>
                            <a
                                href="https://wa.me/5216181448945"
                                target="_blank">
                                <h2>
                                    <img src={whatsapp} alt="" /> 618 144 8945
                                </h2>
                            </a>
                            <h2>Email: curati2023@gmail.com</h2>
                            <h2>Dirección</h2>
                            <p className="dir">
                                Blvd. Domingo Arrieta 1700, Gral Domingo
                                Arrieta, CP 34180, Durango, Dgo., Mexico
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bottom">
                    <Button
                        size="small"
                        sx={{
                            color: '#ffffffbe',
                            textDecoration: 'underline',
                            fontWeight: 'bold',
                            padding: '0',
                        }}
                        onClick={() => {
                            history.push({
                                pathname: '/TerminosCondiciones',
                            });
                        }}>
                        Términos y condiciones
                    </Button>
                    <div className="custom-container">
                        <span>
                            Cura App ® Todos los Derechos Reservados • Durango,
                            Dgo. México • 2023
                        </span>
                    </div>
                </div>
            </footer>
        </div>
    );
};
