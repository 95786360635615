import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import Services from '../../../service/Connection';
import {
    PROFESSORS_SUBJECT_EXPIRE_TIME,
    USERSUI_EXPIRE_TIME,
} from '../../../service/const';
import Feedback from '../../../service/Feedback';
import * as _ from 'lodash';
import Authentication from '../../../service/Login';
import {
    addOneUser,
    selectAllUsers,
    selectEntitiesUsers,
    selectParents,
    selectProfessors,
    setAllUsers,
    upsertManyUsers,
    upsertOneUser,
} from '../entities/users';
import { v4 as uuidv4 } from 'uuid';
import {
    selectAllSubjectcatalogs,
    upsertManySubjectcatalog,
} from '../entities/subjectcatalogs';
import { selectAllSubjects, upsertManySubjects } from '../entities/subjects';
import { selectAllGroups, upsertManyGroup } from '../entities/groups';
import { selectAllStudents, upsertManyStudents } from '../entities/students';
import {
    selectAllRelationships,
    upsertManyRelationships,
} from '../entities/relationships';
import {
    getExpireIn,
    getRelationships,
    getUserRoles,
} from '../../../libs/utils';

const emptyState = {
    home: {
        operations: {
            fetch: {
                expireIn: null,
                fetchingAt: null,
                status: 'idle',
                didInvalidate: true,
                feedback: {
                    title: null,
                    message: null,
                    payload: null,
                },
            },
            createUser: {
                status: 'idle',
                feedback: {
                    title: null,
                    message: null,
                    payload: null,
                },
            },
            updateUser: {
                status: 'idle',
                feedback: {
                    title: null,
                    message: null,
                    payload: null,
                },
            },
            changeNumber: {
                status: 'idle',
                feedback: {
                    title: null,
                    message: null,
                    payload: null,
                },
            },
            changePassword: {
                status: 'idle',
                feedback: {
                    title: null,
                    message: null,
                    payload: null,
                },
            },
        },
        ui: {
            maestros: {
                columnField: '',
                operatorValue: '',
                value: '',
                professor_selected: null,
            },
            administradores: {
                columnField: '',
                operatorValue: '',
                value: '',
                administrator_selected: null,
            },
            padres: {
                columnField: '',
                operatorValue: '',
                value: '',
                parent_selected: null,
            },
            orientadores: {
                columnField: '',
                operatorValue: '',
                value: '',
                assessor_selected: null,
            },
            directores: {
                columnField: '',
                operatorValue: '',
                value: '',
                director_selected: null,
            },
        },
        items: {
            professors: [],
            administrators: [],
            parents: [],
            assessors: [],
            directors: [],
        },
    },
    professors: [],
    administrators: [],
    parents: [],
    assessors: [],
    directors: [],
};

/**
 * Slice para el settings UI
 */
export const usersUISlice = createSlice({
    name: 'usersUI',
    initialState: emptyState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate: (state, action) => {
            state.home.operations.fetch.didInvalidate = true;
        },
        //Invalidadores de UI
        invalidateProfessorsSubjects: (state, { payload }) => {
            state.professors[
                payload
            ].operations.subjects.fetch.didInvalidate = true;
        },
        invalidateParentsStudents: (state, { payload }) => {
            state.parents[
                payload
            ].operations.students.fetch.didInvalidate = true;
        },
        invalidateAssessorsGroups: (state, { payload }) => {
            state.assessors[
                payload
            ].operations.groups.fetch.didInvalidate = true;
        },
        //Filtros
        updateFilter: (state, { payload }) => {
            const { value, filter } = payload;
            state.home.ui = { ...state.home.ui, [value]: filter };
        },

        //ViewModels
        setItemsProfessor: (state, action) => {
            if (Array.isArray(action.payload)) {
                state.home.items.professors = action.payload;
            } else {
                state.home.items.professors = [
                    ...(state.home.items.professors || []),
                    action.payload,
                ];
            }
        },
        addItemsProfessor: (state, action) => {
            let newProfessors = action.payload.reduce((accumulator, item) => {
                accumulator[item.uuid] = item;
                return accumulator;
            }, {});

            // Fusionar los nuevos directores con los existentes
            state.professors = {
                ...state.professors, // Mantener los actuales
                ...newProfessors, // Añadir o actualizar con los nuevos
            };
        },
        setItemsAdministrators: (state, action) => {
            if (Array.isArray(action.payload)) {
                state.home.items.administrators = action.payload;
            } else {
                state.home.items.administrators = [
                    ...(state.home.items.administrators || []),
                    action.payload,
                ];
            }
        },
        addItemsAdministrators: (state, action) => {
            let newAdministrators = action.payload.reduce(
                (accumulator, item) => {
                    accumulator[item.uuid] = item;
                    return accumulator;
                },
                {}
            );

            // Fusionar los nuevos directores con los existentes
            state.administrators = {
                ...state.administrators, // Mantener los actuales
                ...newAdministrators, // Añadir o actualizar con los nuevos
            };
        },
        setItemsParents: (state, action) => {
            if (Array.isArray(action.payload)) {
                state.home.items.parents = action.payload;
            } else {
                state.home.items.parents = [
                    ...(state.home.items.parents || []),
                    action.payload,
                ];
            }
        },
        addItemsParents: (state, action) => {
            let newParents = action.payload.reduce((accumulator, item) => {
                accumulator[item.uuid] = item;
                return accumulator;
            }, {});

            // Fusionar los nuevos directores con los existentes
            state.parents = {
                ...state.parents, // Mantener los actuales
                ...newParents, // Añadir o actualizar con los nuevos
            };
        },
        setItemsAssessors: (state, action) => {
            if (Array.isArray(action.payload)) {
                state.home.items.assessors = action.payload;
            } else {
                state.home.items.assessors = [
                    ...(state.home.items.assessors || []),
                    action.payload,
                ];
            }
        },
        addItemsAssessors: (state, action) => {
            let newAssessros = action.payload.reduce((accumulator, item) => {
                accumulator[item.uuid] = item;
                return accumulator;
            }, {});

            // Fusionar los nuevos directores con los existentes
            state.assessors = {
                ...state.assessors, // Mantener los actuales
                ...newAssessros, // Añadir o actualizar con los nuevos
            };
        },
        setItemsDirectors: (state, action) => {
            if (Array.isArray(action.payload)) {
                state.home.items.directors = action.payload;
            } else {
                state.home.items.directors = [
                    ...(state.home.items.directors || []),
                    action.payload,
                ];
            }
        },
        addItemsDirectors: (state, action) => {
            let newDirectors = action.payload.reduce((accumulator, item) => {
                accumulator[item.uuid] = item;
                return accumulator;
            }, {});

            // Fusionar los nuevos directores con los existentes
            state.directors = {
                ...state.directors, // Mantener los actuales
                ...newDirectors, // Añadir o actualizar con los nuevos
            };
        },
        professorSelected: (state, { payload }) => {
            state.home.ui.maestros.professor_selected = payload;
        },
        administratorSelected: (state, { payload }) => {
            state.home.ui.administradores.administrator_selected = payload;
        },
        parentSelected: (state, { payload }) => {
            state.home.ui.padres.parent_selected = payload;
        },
        assessorSelected: (state, { payload }) => {
            state.home.ui.orientadores.assessor_selected = payload;
        },
        directorSelected: (state, { payload }) => {
            state.home.ui.directores.director_selected = payload;
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });

        const pendingServerStatus = (state, action) => {
            state.home.operations.fetch.status = 'pending';
        };

        /**
         * Termina la carga de informacion
         */
        builder.addCase(loadUI.fulfilled, (state, action) => {
            state.home.operations.fetch.expireIn = getExpireIn(
                'USERSUI_EXPIRE_TIME'
            );
            state.home.operations.fetch.ferchingAt = Date.now();
            state.home.operations.fetch.didInvalidate = false;

            state.home.operations.fetch.status = 'fulfilled';
        });
        builder.addCase(loadUI.pending, pendingServerStatus);
        builder.addCase(loadUI.rejected, (state, action) => {
            state.home.operations.fetch.status = 'rejected';
            state.home.operations.fetch.feedback = action.payload.feedback;
        });

        /////////////////////// ACTUALIZAR USUARIO /////////////////////////

        builder.addCase(updateUser.rejected, (state, action) => {
            state.home.operations.updateUser.status = 'rejected';
            state.home.operations.updateUser.feedback = action.payload.feedback;
        });
        builder.addCase(updateUser.fulfilled, (state, action) => {
            state.home.operations.updateUser.status = 'fulfilled';
        });
        builder.addCase(updateUser.pending, (state, action) => {
            state.home.operations.updateUser.status = 'pending';
        });

        /////////////////////// ACTUALIZAR USUARIO /////////////////////////

        builder.addCase(createUser.rejected, (state, action) => {
            state.home.operations.createUser.status = 'rejected';
            state.home.operations.createUser.feedback = action.payload.feedback;
        });
        builder.addCase(createUser.fulfilled, (state, action) => {
            state.home.operations.createUser.status = 'fulfilled';
        });
        builder.addCase(createUser.pending, (state, action) => {
            state.home.operations.createUser.status = 'pending';
        });

        /////////////////////// ACTUALIZAR CONTRASEÑA DE USUARIO /////////////////////////

        builder.addCase(updatePasswordUser.rejected, (state, action) => {
            state.home.operations.changePassword.status = 'rejected';
            state.home.operations.changePassword.feedback =
                action.payload.feedback;
        });
        builder.addCase(updatePasswordUser.fulfilled, (state, action) => {
            state.home.operations.changePassword.status = 'fulfilled';
        });
        builder.addCase(updatePasswordUser.pending, (state, action) => {
            state.home.operations.changePassword.status = 'pending';
        });

        /////////////////////// ACTUALIZAR TELEFONO DE USUARIO /////////////////////////

        builder.addCase(updateUsername.rejected, (state, action) => {
            state.home.operations.changeNumber.status = 'rejected';
            state.home.operations.changeNumber.feedback =
                action.payload.feedback;
        });
        builder.addCase(updateUsername.fulfilled, (state, action) => {
            state.home.operations.changeNumber.status = 'fulfilled';
        });
        builder.addCase(updateUsername.pending, (state, action) => {
            state.home.operations.changeNumber.status = 'pending';
        });

        /////////////////////// CARGA DE ASESORES EN MODAL /////////////////////////

        builder.addCase(getAssesorsGroups.rejected, (state, { payload }) => {
            const { assessorUUID } = payload;
            state.assessors[assessorUUID].operations.groups.fetch.status =
                'rejected';
        });
        builder.addCase(getAssesorsGroups.fulfilled, (state, { payload }) => {
            const { assessorUUID } = payload;
            state.assessors[assessorUUID].operations.groups.fetch.status =
                'fulfilled';
            state.assessors[assessorUUID].operations.groups.fetch.expireIn =
                new Date().setMinutes(
                    new Date().getMinutes() + PROFESSORS_SUBJECT_EXPIRE_TIME
                );
            state.assessors[assessorUUID].operations.groups.fetch.fetchingAt =
                Date.now();
            state.assessors[
                assessorUUID
            ].operations.groups.fetch.didInvalidate = false;
        });
        builder.addCase(getAssesorsGroups.pending, (state, { meta }) => {
            const { assessorUUID } = meta;
            state.assessors[assessorUUID].operations.groups.fetch.status =
                'pending';
        });

        /////////////////////// CARGA DE PADRES EN MODAL /////////////////////////

        builder.addCase(getParentsStudents.rejected, (state, { payload }) => {
            const { parentUUID } = payload;
            state.parents[parentUUID].operations.students.fetch.status =
                'rejected';
        });
        builder.addCase(getParentsStudents.fulfilled, (state, { payload }) => {
            const { parentUUID } = payload;
            state.parents[parentUUID].operations.students.fetch.status =
                'fulfilled';
            state.parents[parentUUID].operations.students.fetch.expireIn =
                new Date().setMinutes(
                    new Date().getMinutes() + PROFESSORS_SUBJECT_EXPIRE_TIME
                );
            state.parents[parentUUID].operations.students.fetch.fetchingAt =
                Date.now();
            state.parents[
                parentUUID
            ].operations.students.fetch.didInvalidate = false;
        });

        builder.addCase(getParentsStudents.pending, (state, { meta }) => {
            const { parentUUID } = meta;
            state.parents[parentUUID].operations.students.fetch.status =
                'pending';
        });

        /////////////////////// CARGA DE PROFESOR EN MODAL /////////////////////////

        builder.addCase(getProfessorsSubject.rejected, (state, { payload }) => {
            const { professorUUID } = payload;
            state.professors[professorUUID].operations.subjects.fetch.status =
                'rejected';
        });
        builder.addCase(
            getProfessorsSubject.fulfilled,
            (state, { payload }) => {
                const { professorUUID } = payload;
                state.professors[
                    professorUUID
                ].operations.subjects.fetch.expireIn = new Date().setMinutes(
                    new Date().getMinutes() + PROFESSORS_SUBJECT_EXPIRE_TIME
                );
                state.professors[
                    professorUUID
                ].operations.subjects.fetch.status = 'fulfilled';
                state.professors[
                    professorUUID
                ].operations.subjects.fetch.fetchingAt = Date.now();
                state.professors[
                    professorUUID
                ].operations.subjects.fetch.didInvalidate = false;
            }
        );
        builder.addCase(getProfessorsSubject.pending, (state, { meta }) => {
            const { professorUUID } = meta;
            state.professors[professorUUID].operations.subjects.fetch.status =
                'pending';
        });
    },
});

export const {
    invalidate,
    invalidateProfessorsSubjects,
    invalidateParentsStudents,
    invalidateAssessorsGroups,
    updateFilter,
    setItemsProfessor,
    addItemsProfessor,
    setItemsAdministrators,
    addItemsAdministrators,
    setItemsParents,
    addItemsParents,
    setItemsAssessors,
    addItemsAssessors,
    setItemsDirectors,
    addItemsDirectors,
    professorSelected,
    administratorSelected,
    parentSelected,
    assessorSelected,
    directorSelected,
} = usersUISlice.actions;

export default usersUISlice.reducer;

//////////////////// SELECTORES //////////////////
/**
 * Recuperamos las configuraciones de la escuela
 *
 * @param {*} state
 * @returns
 */
export const selectUsersUI = (state) => state.usersUI.home.ui;

export const selectStatusOperation = (state) =>
    state.usersUI.home.operations.fetch.status;

export const selectFetchStatusOperation = (state) =>
    state.usersUI.home.operations.fetch.status;

export const selectCreateStatusOperation = (state) =>
    state.usersUI.home.operations.createUser.status;

export const selectUpdateStatusOperation = (state) =>
    state.usersUI.home.operations.updateUser.status;

export const selectChangeNumberStatusOperation = (state) =>
    state.usersUI.home.operations.changeNumber.status;

export const selectChangePasswordStatusOperation = (state) =>
    state.usersUI.home.operations.changePassword.status;

//////////////// TRUNCKS /////////////////

/**
 * Cargar informacion de la UI
 */
export const loadUI = createAsyncThunk(
    'usersUI/fetch/data',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        let Auth = new Authentication();

        try {
            let professors = await Services.professorsBySchool(schoolId).then(
                (res) => res.data.data
            );
            let parents = await Services.getResourcesPaginatedFromServer(
                'getParentsBySchool',
                [schoolId]
            ).then((res) => res.data);
            let orientador = await Services.getOrientadoresBySchool(
                schoolId
            ).then((res) => res.data.data);
            let administrators = await Services.getAdministratorssBySchool(
                schoolId
            ).then((res) => res.data.data);
            let directors = await Services.getDirectorsBySchool(schoolId).then(
                (res) => res.data.data
            );
            let spokeman = await Services.getVocerosBySchool(schoolId).then(
                (res) => res.data.data
            );

            //Professors ViewModels
            let professorsViewModels = professors.map((professor) => {
                return {
                    uuid: uuidv4(),
                    operations: {
                        subjects: {
                            fetch: {
                                expireIn: null,
                                fetchingAt: null,
                                status: 'idle',
                                didInvalidate: true,
                            },
                        },
                    },
                    ui: {
                        user_id: professor.user_id,
                    },
                };
            });

            //Parents ViewModels
            let parentsViewModels = parents.map((parent) => {
                return {
                    uuid: uuidv4(),
                    operations: {
                        students: {
                            fetch: {
                                expireIn: null,
                                fetchingAt: null,
                                status: 'idle',
                                didInvalidate: true,
                            },
                        },
                    },
                    ui: {
                        user_id: parent.user_id,
                    },
                };
            });

            //Assessors ViewModels
            let assessorsViewModels = orientador.map((orient) => {
                return {
                    uuid: uuidv4(),
                    operations: {
                        groups: {
                            fetch: {
                                expireIn: null,
                                fetchingAt: null,
                                status: 'idle',
                                didInvalidate: true,
                            },
                        },
                    },
                    ui: {
                        user_id: orient.user_id,
                    },
                };
            });

            //Administraros ViewModels
            let administratorsViewModels = administrators.map((admin) => {
                return {
                    uuid: uuidv4(),
                    operations: {},
                    ui: {
                        user_id: admin.user_id,
                    },
                };
            });

            //Directors ViewModels
            let directorsViewModels = directors.map((director) => {
                return {
                    uuid: uuidv4(),
                    operations: {},
                    ui: {
                        user_id: director.user_id,
                    },
                };
            });

            let professorsUUID = professorsViewModels.map(({ uuid }) => {
                return uuid;
            });

            let parentsUUID = parentsViewModels.map(({ uuid }) => {
                return uuid;
            });

            let assessorsUUID = assessorsViewModels.map(({ uuid }) => {
                return uuid;
            });

            let administratorsUUID = administratorsViewModels.map(
                ({ uuid }) => {
                    return uuid;
                }
            );

            let directorsUUID = directorsViewModels.map(({ uuid }) => {
                return uuid;
            });

            let allUsers = _.concat(
                professors,
                parents,
                orientador,
                administrators,
                directors,
                spokeman
            );

            let users = allUsers.map((i) => ({
                ...i,
                user_id: Auth.getUserID(i),
            }));

            thunkAPI.dispatch(setAllUsers(users));
            thunkAPI.dispatch(setItemsProfessor(professorsUUID));
            thunkAPI.dispatch(addItemsProfessor(professorsViewModels));
            thunkAPI.dispatch(setItemsParents(parentsUUID));
            thunkAPI.dispatch(addItemsParents(parentsViewModels));
            thunkAPI.dispatch(setItemsAssessors(assessorsUUID));
            thunkAPI.dispatch(addItemsAssessors(assessorsViewModels));
            thunkAPI.dispatch(setItemsAdministrators(administratorsUUID));
            thunkAPI.dispatch(addItemsAdministrators(administratorsViewModels));
            thunkAPI.dispatch(setItemsDirectors(directorsUUID));
            thunkAPI.dispatch(addItemsDirectors(directorsViewModels));
            return users;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                getState().usersUI.home.operations.fetch;

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

/**
 * Crear usuario
 */
export const createUser = createAsyncThunk(
    'usersUI/user/create',
    async ({ type, schoolId, userData }, thunkAPI) => {
        let FeedbackService = new Feedback();
        let Auth = new Authentication();

        try {
            const typeMap = {
                ADMINISTER: {
                    service:
                        Services.setUserAdministratorsBySchool.bind(Services),
                    viewModel: () => ({}),
                    actions: {
                        set: setItemsAdministrators,
                        add: addItemsAdministrators,
                    },
                },
                PROFESSOR: {
                    service: Services.setUserProfessorBySchool.bind(Services),
                    viewModel: () => ({
                        subjects: {
                            fetch: {
                                expireIn: null,
                                fetchingAt: null,
                                status: 'idle',
                                didInvalidate: true,
                            },
                        },
                    }),
                    actions: {
                        set: setItemsProfessor,
                        add: addItemsProfessor,
                    },
                },
                ASSESSOR: {
                    service: Services.setUserOrientadorBySchool.bind(Services),
                    viewModel: () => ({
                        groups: {
                            fetch: {
                                expireIn: null,
                                fetchingAt: null,
                                status: 'idle',
                                didInvalidate: true,
                            },
                        },
                    }),
                    actions: {
                        set: setItemsAssessors,
                        add: addItemsAssessors,
                    },
                },
                PARENT: {
                    service: Services.storeParentSchool.bind(Services),
                    viewModel: () => ({
                        students: {
                            fetch: {
                                expireIn: null,
                                fetchingAt: null,
                                status: 'idle',
                                didInvalidate: true,
                            },
                        },
                    }),
                    actions: {
                        set: setItemsParents,
                        add: addItemsParents,
                    },
                },
                SPOKESMAN: {
                    service: Services.setUserVoceroBySchool.bind(Services),
                    viewModel: () => ({}),
                    actions: {
                        set: setItemsDirectors,
                        add: addItemsDirectors,
                    },
                },
                DIRECTOR: {
                    service: Services.setUserDirectorBySchool.bind(Services),
                    viewModel: () => ({}),
                    actions: {
                        set: setItemsDirectors,
                        add: addItemsDirectors,
                    },
                },
            };

            // Crear el config
            const config = typeMap[type];

            // Crear usuario con el servicio correspondiente
            const user = await config
                .service(schoolId, userData)
                .then((res) => res.data.data);

            // Crear ViewModel dinámicamente
            const uuid = uuidv4();
            const viewModel = {
                uuid,
                operations: config.viewModel(),
                ui: { user_id: user?.user_id },
            };

            // Configurar usuario autenticado
            Auth.setUser(user);
            user['user_id'] = Auth.getUserID();

            // Actualizar estado global
            thunkAPI.dispatch(addOneUser(user));

            // Ejecutar las acciones en Redux
            thunkAPI.dispatch(config.actions.add([viewModel]));
            thunkAPI.dispatch(config.actions.set(uuid));

            return user;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Actualizar usuario
 */
export const updateUser = createAsyncThunk(
    'usersUI/user/update',
    async ({ userId, permissions, userData, schoolId }, thunkAPI) => {
        let FeedbackService = new Feedback();
        let Auth = new Authentication();

        const roleActions = {
            ADMINISTER: {
                viewModel: () => ({}),
                set: setItemsAdministrators,
                add: addItemsAdministrators,
            },
            PROFESSOR: {
                viewModel: () => ({
                    subjects: {
                        fetch: {
                            expireIn: null,
                            fetchingAt: null,
                            status: 'idle',
                            didInvalidate: true,
                        },
                    },
                }),
                set: setItemsProfessor,
                add: addItemsProfessor,
            },
            PARENT: {
                viewModel: () => ({
                    students: {
                        fetch: {
                            expireIn: null,
                            fetchingAt: null,
                            status: 'idle',
                            didInvalidate: true,
                        },
                    },
                }),
                set: setItemsParents,
                add: addItemsParents,
            },
            DIRECTOR: {
                viewModel: () => ({}),
                set: setItemsDirectors,
                add: addItemsDirectors,
            },
            ASSESSOR: {
                viewModel: () => ({
                    groups: {
                        fetch: {
                            expireIn: null,
                            fetchingAt: null,
                            status: 'idle',
                            didInvalidate: true,
                        },
                    },
                }),
                set: setItemsDirectors,
                add: addItemsDirectors,
            },
        };

        let { group_id } = permissions;

        const currentUser = thunkAPI.getState().entities.users.entities[userId];
        const currentRoles = getUserRoles(currentUser.group_id);

        const roles = group_id.split(',');
        const newRoles = roles.filter((role) => !currentRoles.includes(role));

        try {
            let userPermissionUpdate = await Services.updateUserById(
                userId,
                permissions
            ).then((res) => res.data.data);

            Auth.setUser(userPermissionUpdate);
            let endpoint = Auth.getEndPoint();

            let user = await Services.updateUserData(
                endpoint,
                userId,
                userData
            ).then((res) => res.data.data);

            user['user_id'] = userId;

            thunkAPI.dispatch(upsertOneUser(user));

            newRoles.forEach((role) => {
                const actions = roleActions[role];
                const uuid = uuidv4();
                if (actions) {
                    const viewModel = {
                        uuid,
                        operations: actions.viewModel(),
                        ui: { user_id: user?.user_id },
                    };

                    thunkAPI.dispatch(actions.add([viewModel]));
                    thunkAPI.dispatch(actions.set(uuid));
                } else {
                    console.warn(`No actions defined for role: ${role}`);
                }
            });
            thunkAPI.dispatch(invalidate());
            thunkAPI.dispatch(loadUI(schoolId));
            return {
                user,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Actualizar contraseña
 */
export const updatePasswordUser = createAsyncThunk(
    'usersUI/user/update-password',
    async ({ userId, password, userData }, thunkAPI) => {
        let FeedbackService = new Feedback();
        let Auth = new Authentication();

        try {
            let user = await Services.updateUserById(userId, password).then(
                (res) => res.data.data
            );

            Auth.setUser(user);
            user['user_id'] = userId;
            thunkAPI.dispatch(upsertOneUser(user));

            return {
                user,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Inhabilitar usuario
 */
export const disableUser = createAsyncThunk(
    'usersUI/user/disable',
    async ({ userId, user, data }, thunkAPI) => {
        let FeedbackService = new Feedback();
        let Auth = new Authentication();

        try {
            Auth.setUser(user);
            let endpoint = Auth.getEndPoint();

            let userRespone = await Services.updateUserData(
                endpoint,
                userId,
                data
            ).then((res) => res.data.data);

            userRespone['user_id'] = userId;

            thunkAPI.dispatch(upsertOneUser(userRespone));
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Activar usuario
 */
export const activateUser = createAsyncThunk(
    'usersUI/user/activate',
    async ({ userId, user, data }, thunkAPI) => {
        let FeedbackService = new Feedback();
        let Auth = new Authentication();

        const roleActions = {
            ADMINISTER: {
                viewModel: () => ({}),
                set: setItemsAdministrators,
                add: addItemsAdministrators,
            },
            PROFESSOR: {
                viewModel: () => ({
                    subjects: {
                        fetch: {
                            expireIn: null,
                            fetchingAt: null,
                            status: 'idle',
                            didInvalidate: true,
                        },
                    },
                }),
                set: setItemsProfessor,
                add: addItemsProfessor,
            },
            PARENT: {
                viewModel: () => ({
                    students: {
                        fetch: {
                            expireIn: null,
                            fetchingAt: null,
                            status: 'idle',
                            didInvalidate: true,
                        },
                    },
                }),
                set: setItemsParents,
                add: addItemsParents,
            },
            DIRECTOR: {
                viewModel: () => ({}),
                set: setItemsDirectors,
                add: addItemsDirectors,
            },
            ASSESSOR: {
                viewModel: () => ({
                    groups: {
                        fetch: {
                            expireIn: null,
                            fetchingAt: null,
                            status: 'idle',
                            didInvalidate: true,
                        },
                    },
                }),
                set: setItemsDirectors,
                add: addItemsDirectors,
            },
        };

        try {
            Auth.setUser(user);
            let endpoint = Auth.getEndPoint();

            let userRespone = await Services.updateUserData(
                endpoint,
                userId,
                data
            ).then((res) => res.data.data);

            userRespone['user_id'] = userId;

            const currentRoles = getUserRoles(userRespone.group_id);

            currentRoles.forEach((role) => {
                const actions = roleActions[role];
                const uuid = uuidv4();
                if (actions) {
                    const viewModel = {
                        uuid,
                        operations: actions.viewModel(),
                        ui: { user_id: user?.user_id },
                    };

                    thunkAPI.dispatch(actions.add([viewModel]));
                    thunkAPI.dispatch(actions.set(uuid));
                } else {
                    console.warn(`No actions defined for role: ${role}`);
                }
            });

            thunkAPI.dispatch(upsertOneUser(userRespone));
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Actualizar usuario
 */
export const updateUsername = createAsyncThunk(
    'usersUI/user/update-number',
    async ({ userId, cellphone }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            let user = await Services.updateUserById(userId, cellphone).then(
                (res) => res.data.data
            );

            user['user_id'] = userId;

            thunkAPI.dispatch(upsertOneUser(user));

            return {
                user,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

export const getAssesorsGroups = createAsyncThunk(
    'usersUI/data/assesorsGroups',
    async (assesorsId, thunkAPI) => {
        let FeedbackService = new Feedback();
        const state = thunkAPI.getState();
        const assessorUUID =
            state.usersUI.home.ui.orientadores.assessor_selected;
        try {
            const groups = await Services.getSchoolByAssessor(assesorsId).then(
                (res) => res.data.data
            );
            thunkAPI.dispatch(upsertManyGroup(groups));
            return { groups, assessorUUID };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                assessorUUID,
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState }) => {
            const state = getState();
            const assessorUUID =
                state.usersUI.home.ui.orientadores.assessor_selected;
            let { didInvalidate, expireIn } =
                state.usersUI.assessors[assessorUUID].operations.groups.fetch;

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
        getPendingMeta: ({ ard, requestId }, { getState, extra }) => {
            return {
                assessorUUID:
                    getState().usersUI.home.ui.orientadores.assessor_selected,
            };
        },
    }
);

export const getParentsStudents = createAsyncThunk(
    'usersUI/data/parentsStudents',
    async ({ parentId, schoolId }, thunkAPI) => {
        let FeedbackService = new Feedback();
        const state = thunkAPI.getState();
        const parentUUID = state.usersUI.home.ui.padres.parent_selected;
        try {
            const students = await Services.getStudentsByParent(
                parentId,
                schoolId
            ).then((res) => res.data.data);

            let relationships = [];
            let groups = [];
            let allParents = [];

            for (let student of students) {
                let studentRelationships =
                    await Services.getRelationshipsByStudents(
                        student.student_id
                    ).then((res) => res.data.data);

                let studentsGroup = await Services.getGroupByStudent(
                    student.student_id
                ).then((res) => res.data.data);

                let parents = await Services.getParentsByStudent(
                    student.student_id
                ).then((res) => res.data.data);

                let users = parents.map((i) => ({
                    ...i,
                    user_id: new Authentication().getUserID(i),
                }));

                allParents = allParents.concat(users);
                relationships = relationships.concat(studentRelationships);

                if (studentsGroup) {
                    groups = groups.concat(studentsGroup);
                }
            }

            thunkAPI.dispatch(upsertManyStudents(students));
            thunkAPI.dispatch(upsertManyRelationships(relationships));
            thunkAPI.dispatch(upsertManyGroup(groups));
            thunkAPI.dispatch(upsertManyUsers(allParents));

            return { parentUUID, students, parents: allParents };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                parentUUID,
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState }) => {
            const state = getState();
            const parentUUID = state.usersUI.home.ui.padres.parent_selected;
            let { didInvalidate, expireIn } =
                state.usersUI.parents[parentUUID].operations.students.fetch;
            const valid = expireIn > Date.now();
            if (!didInvalidate && valid) {
                return false;
            }
        },
        getPendingMeta: ({ arg, requestID }, { getState, extra }) => {
            return {
                parentUUID: getState().usersUI.home.ui.padres.parent_selected,
            };
        },
    }
);

export const getProfessorsSubject = createAsyncThunk(
    'usersUI/data/professorsSubject',
    async (professorId, thunkAPI) => {
        let FeedbackService = new Feedback();
        const state = thunkAPI.getState();
        const professorUUID = state.usersUI.home.ui.maestros.professor_selected;

        try {
            let catalogSubjects = [];

            //Grupos del profesor
            const groups = await Services.getGroupsByProfessor(
                professorId
            ).then((res) => res.data.data);

            //Materias
            const subjects = await Services.getSubjectsByProfessor(
                professorId
            ).then((res) => res.data.data);

            //Catalogo de materias
            for (let subject of subjects) {
                let catalogs = await Services.getSubjectCatalogBySubject(
                    subject.subject_id
                ).then((res) => res.data.data);
                catalogSubjects = catalogSubjects.concat(catalogs);
            }

            thunkAPI.dispatch(upsertManySubjects(subjects));
            thunkAPI.dispatch(upsertManySubjectcatalog(catalogSubjects));
            thunkAPI.dispatch(upsertManyGroup(groups));

            return {
                professorUUID,
                subjects,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                professorUUID,
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState }) => {
            const state = getState();
            const professorUUID =
                state.usersUI.home.ui.maestros.professor_selected;

            let { didInvalidate, expireIn } =
                state.usersUI.professors[professorUUID].operations.subjects
                    .fetch;
            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
        getPendingMeta: ({ arg, requestId }, { getState, extra }) => {
            return {
                professorUUID:
                    getState().usersUI.home.ui.maestros.professor_selected,
            };
        },
    }
);

//////////////////////////////////// SELECTOR ////////////////////////////////////////

export const selectAssesorsGroups = (state, assesorsId) =>
    state.usersUI.data.assesorsGroups[assesorsId]?.groups || [];

export const selectAssesorsGroupsServer = (state, assesorsId) =>
    state.usersUI.data.assesorsGroups[assesorsId]?.server || {};

// export const selectParentsStudents = (state, parentIds) =>
//     state.usersUI.data.parentsStudents[parentIds]?.student || [];

export const selectParentsStudentsServer = (state, parentIds) =>
    state.usersUI.data.parentsStudents[parentIds]?.server || {};

export const selectProfessorsSubjectServerStatusOperation = (
    state,
    professorId
) => {
    return state.usersUI.data.professorsSubject[professorId]?.server
        .statusServer;
};

export const selectProfessorsSubjectServerStatusServer = (state, professorId) =>
    state.usersUI.data.professorsSubject[professorId]?.server.statusServer;

export const selectProfessorsSubject = (state, professorId) => {
    const professorData = Object.values(
        state.usersUI.data.professorsSubject
    ).find((sub) => sub.professorId === professorId);
    return professorData ? professorData.subjects : [];
};

export const selectSubjectsByProfessor = (state, professorId) => {
    const subjectIds =
        state.usersUI.data.professorsSubject[professorId]?.subjects || [];
    return subjectIds.map((id) => state.subjects.entities[id]);
};

export const selectRecordByUniqueId = (state, uniqueId) => {
    const record = state.usersUI.data.professorsSubject.items[uniqueId];

    if (!record) return null;

    return {
        professorId: record.professorId,
        subjectsId: record.subjectsId,
        catalogSubjectIds: record.catalogSubjectId,
    };
};

export const selectUsersHome = (state) => state.usersUI.home;
export const selectUsersProfessors = (state) => state.usersUI.professors;
export const selectUsersParents = (state) => state.usersUI.parents;
export const selectUsersAssessors = (state) => state.usersUI.assessors;
export const selectUsersAdministrators = (state) =>
    state.usersUI.administrators;
export const selectUsersDirectors = (state) => state.usersUI.directors;

export const selectUsersHomeUI = createSelector(selectUsersHome, (home) => {
    return home.ui;
});

export const selectUsersHomeUIProfessors = createSelector(
    selectUsersHomeUI,
    (ui) => {
        return ui.maestros;
    }
);

export const selectUsersHomeUIParents = createSelector(
    selectUsersHomeUI,
    (ui) => {
        return ui.padres;
    }
);

export const selectUsersHomeUIAssessors = createSelector(
    selectUsersHomeUI,
    (ui) => {
        return ui.orientadores;
    }
);

export const selectProfessorSelected = createSelector(
    selectUsersHomeUIProfessors,
    (uiProfessors) => {
        return uiProfessors.professor_selected;
    }
);

export const selectParentSelected = createSelector(
    selectUsersHomeUIParents,
    (uiParents) => {
        return uiParents.parent_selected;
    }
);

export const selectAssessorSelected = createSelector(
    selectUsersHomeUIAssessors,
    (uiAssessors) => {
        return uiAssessors.assessor_selected;
    }
);

export const selectHomeItems = createSelector(selectUsersHome, (home) => {
    return home.items;
});

export const selectHomeProfessorItems = createSelector(
    selectHomeItems,
    (items) => {
        return items.professors;
    }
);

export const selectHomeParentItems = createSelector(
    selectHomeItems,
    (items) => {
        return items.parents;
    }
);

export const selectHomeAssessorItems = createSelector(
    selectHomeItems,
    (items) => {
        return items.assessors;
    }
);

export const selectHomeAdministratorItems = createSelector(
    selectHomeItems,
    (items) => {
        return items.administrators;
    }
);

export const selectHomeDirectorItems = createSelector(
    selectHomeItems,
    (items) => {
        return items.directors;
    }
);

export const selectSelectedProfessorItem = createSelector(
    selectUsersProfessors,
    selectProfessorSelected,
    (professorItems, selectedProfessor) => {
        return professorItems[selectedProfessor];
    }
);

export const selectSelectedProfessorOperations = createSelector(
    selectSelectedProfessorItem,
    (selectedProfessorItem) => {
        return selectedProfessorItem.operations;
    }
);

export const selectSelectedProfesorSubjectFetch = createSelector(
    selectSelectedProfessorOperations,
    (professorOperations) => {
        return professorOperations.subjects.fetch;
    }
);

export const selectSelectedProfessorStatus = createSelector(
    selectSelectedProfesorSubjectFetch,
    (professorFetch) => {
        return professorFetch.status;
    }
);

export const selectSelectedParentsItem = createSelector(
    selectUsersParents,
    selectParentSelected,
    (parentsItems, selectedParent) => {
        return parentsItems[selectedParent];
    }
);

export const selectSelectedParentsOperations = createSelector(
    selectSelectedParentsItem,
    (selectedParentItem) => {
        return selectedParentItem.operations;
    }
);

export const selectSelectedParentsStudentsFetch = createSelector(
    selectSelectedParentsOperations,
    (parentsOperations) => {
        return parentsOperations.students.fetch;
    }
);

export const selectSelectedParentStatus = createSelector(
    selectSelectedParentsStudentsFetch,
    (parentFetch) => {
        return parentFetch.status;
    }
);

export const selectSelectedAssessorItem = createSelector(
    selectUsersAssessors,
    selectAssessorSelected,
    (assessorItem, selectedAsssessor) => {
        return assessorItem[selectedAsssessor];
    }
);

export const selecSelectedAssessorOperations = createSelector(
    selectSelectedAssessorItem,
    (selectedAssessorItem) => {
        return selectedAssessorItem.operations;
    }
);

export const selectSelectedAssessorGroupFetch = createSelector(
    selecSelectedAssessorOperations,
    (assessorOperations) => {
        return assessorOperations.groups.fetch;
    }
);

export const selectSelectedAssessorStatus = createSelector(
    selectSelectedAssessorGroupFetch,
    (assessorFetch) => {
        return assessorFetch.status;
    }
);

export const selectProfessorsView = createSelector(
    selectUsersProfessors,
    selectHomeProfessorItems,
    selectEntitiesUsers,
    (professorsItems, professorsUUIDS, userEntities) => {
        return professorsUUIDS.map((i) => {
            let currentViewModel = professorsItems[i];
            return {
                ...userEntities[currentViewModel.ui.user_id],
                viewModel: currentViewModel,
            };
        });
    }
);

export const selectProfessorsSubjects = (vmUUID) =>
    createSelector(
        selectProfessorsView,
        selectAllSubjects,
        selectAllSubjectcatalogs,
        selectAllGroups,
        (professorsVM, subjects, subjectCatalogs, groups) => {
            const currentVM = professorsVM.find(
                (vm) => vm.viewModel.uuid === vmUUID
            );
            const professorSubjetcs = subjects.filter(
                (subject) => subject.professor_id === currentVM.user_id
            );

            const subjectsWithCatalogs = professorSubjetcs.map((subject) => {
                const catalog = subjectCatalogs.find(
                    (cat) =>
                        cat.catalog_subject_id === subject.catalog_subject_id
                );
                const group = groups.find(
                    (group) => group.group_id === subject.group_id
                );
                return {
                    ...subject,
                    catalog: catalog || null,
                    group: group || null,
                };
            });

            return subjectsWithCatalogs;
        }
    );

export const selectParentsView = createSelector(
    selectUsersParents,
    selectHomeParentItems,
    selectEntitiesUsers,
    (parentsItems, parentsUUIDS, userEntities) => {
        return parentsUUIDS.map((i) => {
            let currentViewModel = parentsItems[i];
            return {
                ...userEntities[currentViewModel.ui.user_id],
                viewModel: currentViewModel,
            };
        });
    }
);

export const selectParentsStudents = (vmUUID) =>
    createSelector(
        selectParentsView,
        selectAllRelationships,
        selectAllStudents,
        selectAllGroups,
        selectParents,
        (parentsVM, relationships, students, groups, parents) => {
            const currentVM = parentsVM.find(
                (vm) => vm.viewModel.uuid === vmUUID
            );

            const parentRelationships = relationships.filter(
                (relationship) => relationship.parent_id === currentVM.user_id
            );

            const studentsWithRelationship = parentRelationships.map(
                (relationship) => {
                    const foundStudent = students.find(
                        (student) =>
                            student.student_id === relationship.student_id
                    );

                    if (!foundStudent) {
                        return null;
                    }

                    const group = groups.find(
                        (group) => group.group_id === foundStudent.group_id
                    );

                    //const relationshipTitle = getRelationships(relationship.relationship).title;

                    const relatedParents = relationships
                        .filter(
                            (rel) => rel.student_id === foundStudent.student_id
                        )
                        .map((rel) => {
                            const parentDetails = parents.find(
                                (parent) => parent.user_id === rel.parent_id
                            );
                            return {
                                ...parentDetails,
                                relationship: getRelationships(rel.relationship)
                                    .title,
                            };
                        });

                    return {
                        ...foundStudent,
                        group: group || null,
                        parents: relatedParents,
                    };
                }
            );

            const result = studentsWithRelationship.filter(Boolean);

            return result;
        }
    );

export const selectAssessorsView = createSelector(
    selectUsersAssessors,
    selectHomeAssessorItems,
    selectEntitiesUsers,
    (assessorsItems, assessorsUUIDS, userEntities) => {
        return assessorsUUIDS.map((i) => {
            let currentViewModel = assessorsItems[i];
            return {
                ...userEntities[currentViewModel.ui.user_id],
                viewModel: currentViewModel,
            };
        });
    }
);

export const selectAssessorsGroups = (vmUUID) =>
    createSelector(
        selectAssessorsView,
        selectAllGroups,
        (assessrosVM, groups) => {
            const currentVM = assessrosVM.find(
                (vm) => vm.viewModel.uuid === vmUUID
            );
            return groups.filter(
                (group) => group.assessor_id === currentVM.user_id
            );
        }
    );

export const selectAdministratorsView = createSelector(
    selectUsersAdministrators,
    selectHomeAdministratorItems,
    selectEntitiesUsers,
    (administratorsItems, administratorsUUIDS, userEntities) => {
        return administratorsUUIDS.map((i) => {
            let currentViewModel = administratorsItems[i];
            return {
                ...userEntities[currentViewModel.ui.user_id],
                viewModel: currentViewModel,
            };
        });
    }
);

export const selectDirectorsView = createSelector(
    selectUsersDirectors,
    selectHomeDirectorItems,
    selectEntitiesUsers,
    (directorsItems, directorsUUIDS, userEntities) => {
        return directorsUUIDS.map((i) => {
            let currentViewModel = directorsItems[i];
            return {
                ...userEntities[currentViewModel.ui.user_id],
                viewModel: currentViewModel,
            };
        });
    }
);
