import * as Yup from 'yup';

// Validaciones reutilizables
const nameValidation = Yup.string()
    .matches(/^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/, 'Solo se permiten letras y espacios')
    .required('Campo requerido');

const optionalNameValidation = Yup.string()
    .matches(/^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/, 'Solo se permiten letras y espacios')
    .notRequired();

const emailValidation = Yup.string().email('Correo inválido').notRequired();

const postalCodeValidation = Yup.string()
    .matches(/^[0-9]{5}$/, 'Debe ser un código postal válido de 5 dígitos')
    .required('El código postal es obligatorio');

const phoneValidation = Yup.string()
    .matches(/^[0-9]{10}$/, 'Debe ser un número de 10 dígitos')
    .required('El número de celular es obligatorio');

// Esquema de validación actualizado
export const validationSchema = Yup.object({
    parent_name: nameValidation,
    parent_last_name: nameValidation,
    parent_second_last_name: optionalNameValidation,
    parent_phone: phoneValidation,
    parent_email: emailValidation,
    parent_birth_date: Yup.date()
        .required('La fecha de nacimiento es obligatoria')
        .max(new Date(), 'La fecha no puede ser en el futuro'),
    parent_gender: Yup.string()
        .required('El género es obligatorio')
        .oneOf(['1', '2'], 'Género inválido'),
    relationship: Yup.string()
        .required('El tipo de parentesco es obligatorio')
        .oneOf(['1', '2', '3', '4'], 'El tipo de parentesco no es válido'),
    work_street: Yup.string().notRequired('La calle es obligatoria'),
    work_neighborhood: Yup.string().notRequired('La colonia es obligatoria'),
    work_zipcode: Yup.string()
        .matches(/^[0-9]{5}$/, 'Debe ser un código postal válido de 5 dígitos')
        .notRequired('El código postal es obligatorio'),
    work_external_number: Yup.string().notRequired(
        'El número exterior es obligatorio'
    ),
    work_internal_number: Yup.string().notRequired(),

    //ESTADO MUNICIPIO CIUDAD
    parent_birth_state_id: Yup.string().required('La ciudad es obligatoria'),
    parent_birth_minicipality_id: Yup.string().required(
        'La ciudad es obligatoria'
    ),
    parent_birth_city_id: Yup.string().required('La ciudad es obligatoria'),

    gpsLocation: Yup.string().notRequired(),
    parent_home_street: Yup.string().required('La calle es obligatoria'),
    parent_home_external_number: Yup.string().required(
        'El número exterior es obligatorio'
    ),
    parent_home_internal_number: Yup.string().notRequired(),
    parent_home_neighborhood: Yup.string().required(
        'La colonia es obligatoria'
    ),
    parent_home_zipcode: postalCodeValidation,
});
