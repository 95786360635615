import React from 'react';
import { StudentInformation } from '../pages/StudentInformation';
import { StudentParent } from '../pages/StudentParent';
import { StudentArchives } from '../pages/StudentArchives';
import { StudentPostulation } from '../pages/StudentPostulation';
import { Summary } from '../pages/Summary';
import { StudentLevel } from '../pages/StudentLevel';

export const getStepContent = (
    step,
    formData,
    setFormData,
    activeStep,
    handleBack,
    handleNext,
    uuid
) => {
    switch (step) {
        case 0:
            return (
                <StudentLevel
                    data={formData}
                    setData={setFormData}
                    handleBack={handleBack}
                    handleNext={handleNext}
                />
            );
        case 1:
            return (
                <StudentInformation
                    data={formData}
                    setData={setFormData}
                    activeStep={activeStep}
                    steps={step}
                    handleBack={handleBack}
                    handleNext={handleNext}
                />
            );
        case 2:
            return (
                <StudentParent
                    data={formData}
                    setData={setFormData}
                    activeStep={activeStep}
                    steps={step}
                    handleBack={handleBack}
                    handleNext={handleNext}
                />
            );
        case 3:
            return (
                <StudentArchives
                    data={formData}
                    setData={setFormData}
                    activeStep={activeStep}
                    steps={step}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    uuid={uuid}
                />
            );
        case 4:
            return (
                <StudentPostulation
                    data={formData}
                    setData={setFormData}
                    activeStep={activeStep}
                    steps={step}
                    handleBack={handleBack}
                    handleNext={handleNext}
                />
            );
        default:
            return (
                <Summary
                    data={formData}
                    activeStep={activeStep}
                    steps={step}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    uuid={uuid}
                />
            );
    }
};
