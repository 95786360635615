import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const tracesCatalogsAdaptar = createEntityAdapter({
    selectId: (traceCatalog) => traceCatalog.trace_catalog_id,
    sortComparer: (a, b) => a.trace_catalog_id - b.trace_catalog_id,
});

export const tracesCatalogsSlice = createSlice({
    name: 'tracesCatalogs',
    initialState: tracesCatalogsAdaptar.getInitialState(),
    reducers: {
        addOneTraceCatalog: tracesCatalogsAdaptar.addOne,
        addManyTracesCatalogs: tracesCatalogsAdaptar.addMany,
        upsertManyTracesCatalogs: tracesCatalogsAdaptar.upsertMany,
    },
});

export const {
    addOneTraceCatalog,
    addManyTracesCatalogs,
    upsertManyTracesCatalogs,
} = tracesCatalogsSlice.actions;

export default tracesCatalogsSlice.reducer;

export const globalizedSelectors = tracesCatalogsAdaptar.getSelectors(
    (state) => state.entities.tracesCatalogs
);

export const selectTracesCatalogsEntities = (state) =>
    globalizedSelectors.selectEntities(state);

export const selectAllTracesCatalogs = (state) =>
    globalizedSelectors.selectAll(state);
